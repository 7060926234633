import {
  Card,
  TextField,
  Tabs,
  Tab,
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallMissedIcon from '@mui/icons-material/CallMissed';
import CallIcon from '@mui/icons-material/Call';

import Iconify from "../../components/iconify/Iconify";
import ReportTable from "./reportTable";
import Branches from "../../Master/branches";
import USERSROUTES from "../../API_Routes/User/User_Routers";
import { getData } from "../../webService/webService";

function Reports() {
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const accessToken = sessionStorage.getItem("Token");
  const [dateField, setDateField] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [clearSelectedDate, setClearSelectedDate] = useState(false);
  const [branch, setBranch] = useState("");
  const [duration, setDurationField] = useState('');
  const [hour, setHour] = useState('00');
  const [minutes, setMinutes] = useState('00');
  const [seconds, setSeconds] = useState('00');


  useEffect(() => {
    console.log('duration', duration)
  }, [duration])

  const handleBranchChange = (event) => {
    setBranch(event.target.value);
  };

  // Get Employee list
  const [teleCallerName, setteleCallerName] = useState("");
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
      },
    },
    MenuListProps: {
      style: {
        maxHeight: 200,
      },
    },
  };
  const [EmployeeList, setEmployeeList] = useState();
  const [TotalEmployeeList, setTotalEmployeeList] = useState(1);
  const fetchdata = () => {
    const params = {
      page: 1,
      limit: TotalEmployeeList,
      role: "Employee",
      branch: branch,
    };
    getData(USERSROUTES.GETALLUSER, params, accessToken, false)
      .then((res) => {
        if (res.status === 404) {
          setEmployeeList();
        } else {
          setEmployeeList(res.data[0].paginatedResults);
          setTotalEmployeeList(res.data[0].totalPages);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    if (TotalEmployeeList > 1) {
      fetchdata();
    }
  }, [TotalEmployeeList]);
  useEffect(() => {
    fetchdata();
  }, [branch]);
  const handleChange = (event) => {
    setteleCallerName(event.target.value);
  };
  // Get Employee list

  const handleDurationFilter = () => {
    setDurationField(`${hour}h ${minutes}m ${seconds}s`)
  }

  // Handle input change to update the state with the selected date
  const handleInputChange = (event) => {
    setDateField(event.target.value);
    const date = moment(event.target.value);
    setStartDate(date.startOf("day").format("YYYY-MM-DD HH:mm:ss"));
    setEndDate(date.endOf("day").format("YYYY-MM-DD HH:mm:ss"));
  };
  const clearDateField = () => {
    setDateField("");
    setEndDate("");
    setStartDate("");
    setClearSelectedDate(true);
    setBranch("");
    setteleCallerName("");
    setDurationField("");
    setSeconds('00')
    setHour('00')
    setMinutes('00')
  };


  return (
    <>
      <ToastContainer limit={1} />
      <Helmet>
        <title>Reports</title>
      </Helmet>
      <h5 className="title-content-common">Reports</h5>
      <Card className="p-4 main-card-box">
        <div className="d-flex justify-content-start mb-4">
          {isAuthenticated === "Super-Admin" && (
            <div className="col-2 mx-2">
              <Box mb={2}>
                <FormControl size="small" className="w-100">
                  <InputLabel id="branch-select-label">Branch</InputLabel>
                  <Select
                    labelId="branch-select-label"
                    id="branch-select"
                    value={branch}
                    label="Branch"
                    onChange={handleBranchChange}
                  >
                    {Branches.map((branch) => (
                      <MenuItem key={branch} value={branch}>
                        {branch}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
          )}
          <div className="col-2 mx-2">
            <FormControl fullWidth margin="normal" className="m-0" size="small">
              <InputLabel style={{ background: "white" }}>
                Tele Caller Name
              </InputLabel>
              <Select
                label="Tele"
                name="teleCallerName"
                value={teleCallerName}
                onChange={handleChange}
                MenuProps={MenuProps}
              >
                {EmployeeList?.map((employee, index) => (
                  <MenuItem
                    key={index}
                    value={`${employee.firstName} ${employee.lastName}`}
                  >
                    {employee.firstName} {employee.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <TextField
            label="Hour"
            className="mx-2"
            type="number"
            variant="outlined"
            style={{ width: "8%" }}
            name="hour"
            value={hour}
            onChange={(event) => {
              setHour(event?.target?.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
          <TextField
            label="Minutes"
            className="mx-2"
            type="number"
            variant="outlined"
            style={{ width: "8%" }}
            name="minutes"
            value={minutes}
            onChange={(event) => setMinutes(event?.target?.value)}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
          <TextField
            label="Seconds"
            className="mx-2"
            type="number"
            variant="outlined"
            style={{ width: "8%" }}
            name="seconds"
            value={seconds}
            onChange={(event) => setSeconds(event?.target?.value)}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
          <Button
            variant="outlined"
            startIcon={<Iconify icon="mingcute:time-duration-fill" />}
            style={{ height: "40px", width: "160px" }}
            onClick={handleDurationFilter}
          >
            Set Duration
          </Button>
          <TextField
            label="Date"
            className="mx-2"
            type="date"
            variant="outlined"
            style={{ width: "15%" }}
            name="dateField"
            value={dateField}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
          {(dateField || branch || teleCallerName || duration) && (
            <Button
              variant="outlined"
              className="ms-2"
              startIcon={<Iconify icon="mdi:clear-outline" />}
              onClick={clearDateField}
              style={{ height: "40px", width: "160px" }}
            >
              Clear Filters
            </Button>
          )}
        </div>
        <Box>
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#f8bd51",
              },
              "& .MuiTab-root": {
                textTransform: "none",
                color: "#000",
              },
              "& .Mui-selected": {
                color: "#c6150f!important",
              },
            }}
          >
            <Tab label="All Calls" icon={<CallIcon />} />
            <Tab label="Missed Calls" icon={<CallMissedIcon />} />
            <Tab label="Incoming Calls" icon={<CallReceivedIcon />} />
            <Tab label="Outcoming Calls" icon={<CallMadeIcon />} />
          </Tabs>
        </Box>

        <TabPanel value={activeTab} index={0}>
          <ReportTable
            Type={""}
            dateField={dateField}
            startDate={startDate}
            endDate={endDate}
            clearSelectedDate={clearSelectedDate}
            setClearSelectedDate={setClearSelectedDate}
            branch={branch}
            duration={duration}
            teleCallerName={teleCallerName}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <ReportTable
            Type={"MISSED"}
            dateField={dateField}
            startDate={startDate}
            endDate={endDate}
            clearSelectedDate={clearSelectedDate}
            setClearSelectedDate={setClearSelectedDate}
            branch={branch}
            duration={duration}
            teleCallerName={teleCallerName}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <ReportTable
            Type={"INCOMING"}
            dateField={dateField}
            startDate={startDate}
            endDate={endDate}
            clearSelectedDate={clearSelectedDate}
            setClearSelectedDate={setClearSelectedDate}
            branch={branch}
            duration={duration}
            teleCallerName={teleCallerName}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={3}>
          <ReportTable
            Type={"OUTGOING"}
            dateField={dateField}
            startDate={startDate}
            endDate={endDate}
            clearSelectedDate={clearSelectedDate}
            setClearSelectedDate={setClearSelectedDate}
            branch={branch}
            duration={duration}
            teleCallerName={teleCallerName}
          />
        </TabPanel>
      </Card>
    </>
  );
}

// TabPanel component to conditionally render content based on the selected tab
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: "20px 0px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default Reports;
