import {
  Badge,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TextField,
  Typography,
  Select,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import * as XLSX from "sheetjs-style";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import { toast, ToastContainer } from "react-toastify";

import Scrollbar from "../../components/scrollbar";
import Iconify from "../../components/iconify/Iconify";
import { deleteData, getData } from "../../webService/webService";
import MISROUTES from "../../API_Routes/MIS/MIS_Routers";
import USERSROUTES from "../../API_Routes/User/User_Routers";
import MASTERROUTES from "../../API_Routes/Master/Master_Routers";
import { showConfirmationDialog } from "../../utils/sweetalertUtils";
import { formatToRupees } from "../../utils/formatRupees";
import MISComponent from "./mis";
import Branches from "../../Master/branches";
import { HeaderStylesForExportExcel } from "../../css/ExportExcelCSS";
import { TableHeadingCellStyles, TableBodyCellStyles } from "../../css/tableCSS";

function MISReport() {
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const Branch = sessionStorage?.getItem('Branch')
  const [MIS_List, setMIS_List] = useState([]);
  const [EmployeeList, setEmployeeList] = useState([]);
  const [TotalEmployeeList, setTotalEmployeeList] = useState(1);
  const [CurrentLoanProcessedBank, setCurrentLoanProcessedBank] = useState([]);
  const [TypeOfLoan, setTypeOfLoan] = useState([]);
  const [ExistingLoanProcessedBankList, setExistingLoanProcessedBankList] = useState([]);
  const [MIS_StatusList, setMIS_StatusList] = useState([]);
  const accessToken = sessionStorage.getItem("Token");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [branch, setBranch] = useState("");
  const [TelecallerName, setTeleCallerName] = useState("");
  const [isFilterClear, setIsFilterClear] = useState("");

  // Table Conditions
  const [open, setOpen] = useState(null);
  const [adduseropen, setadduserOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserData, setSelectedUserData] = useState(null);

  const [CLPB, setCLPB] = useState('');
  const [ELPB, setELPB] = useState('');
  const [TypeOfLoanState, setTypeOfLoanState] = useState('');
  const [MIS_Status, setMIS_Status] = useState('');

  const [DisableDownloadButton, setDisableDownloadButton] = useState('');

  const handleBranchChange = (event) => {
    setBranch(event.target.value);
  };

  const handleCLPBChange = (event) => {
    setCLPB(event.target.value);
  };

  const handleELPBChange = (event) => {
    setELPB(event.target.value);
  };

  const handleMIS_StatusChange = (event) => {
    setMIS_Status(event.target.value);
  };

  const handleTypeOfLoanChange = (event) => {
    setTypeOfLoanState(event.target.value);
  };

  const handleTeleCallerNameChange = (event) => {
    setTeleCallerName(event.target.value);
  };

  const handleSearch = () => {
    filterData();
  };

  const filterData = () => {
    fetchdata(currentPage)
  }

  const removeFilter = () => {
    setStartDate("");
    setEndDate("");
    setBranch("");
    setTeleCallerName("");
    setCLPB("");
    setELPB("");
    setMIS_Status("");
    setTypeOfLoanState("");
    setIsFilterClear("yes");
  };

  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setSelectedUserId(id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setSelectedUserId(null);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [limit, setLimit] = useState(5);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleLimitChange = (value) => {
    setCurrentPage(1);
    setLimit(Number(value));
  };

  const fetchdata = async (pageval) => {
    try {
      console.log('branch', branch)
      console.log('startDate', startDate)
      const queryParams = {
        page: pageval,
        limit: limit,
        branch:
          isAuthenticated === 'Branch-Admin' ? Branch : branch,
        telecallername: TelecallerName,
        typeofloan: TypeOfLoanState,
        currentloanprocessedbank: CLPB,
        existingloanprocessedbank: ELPB,
        status: MIS_Status,
        startdate: startDate,
        enddate: endDate
      };
      const MIS_Res = await getData(MISROUTES.GETALLMIS, queryParams, accessToken);
      if (MIS_Res?.status === 404) {
        setMIS_List([]);
        setTotalPages(1);
        setTotalItems(0);
        setDisableDownloadButton(true);
      }
      else {
        const MIS_DATA = MIS_Res?.data?.[0]?.paginatedResults ? MIS_Res?.data?.[0]?.paginatedResults : [];
        if (MIS_DATA?.length === 0) {
          setDisableDownloadButton(true);
        }
        else {
          setDisableDownloadButton(false);
        }
        setMIS_List([...MIS_DATA]);
        setTotalPages(MIS_Res?.data?.[0]?.totalPages);
        setTotalItems(MIS_Res?.data?.[0]?.totalCount?.totalItems ? MIS_Res?.data?.[0]?.totalCount?.totalItems : 0);
        setIsFilterClear("");
      }
    }
    catch (error) {
      console.error(error)
      setMIS_List([]);
      setTotalPages(1);
      setTotalItems(0);
      setDisableDownloadButton(true);
    }
  };

  const fetchUsers = async () => {
    try {
      const queryParamsUser = {
        page: 1,
        limit: TotalEmployeeList,
        role: "Employee",
        branch: isAuthenticated === 'Branch-Admin' ? Branch : branch,
      };
      const UserRes = await getData(
        USERSROUTES.GETALLUSER,
        queryParamsUser,
        accessToken,
        false
      )
      if (UserRes?.status === 404) {
        setEmployeeList([]);
      } else {
        setEmployeeList(UserRes?.data?.[0]?.paginatedResults);
        setTotalEmployeeList(UserRes?.data?.[0]?.totalPages);
      }
    }
    catch (error) {
      console.error(error)
      setEmployeeList([]);
      setTotalEmployeeList(1);
    }
  };


  const fetchDropDownData = (data) => {
    getData(MASTERROUTES.GETLIST(data), null, accessToken, false)
      .then((res) => {
        if (data === "CurrentLoanProcessedBank") {
          setCurrentLoanProcessedBank(res?.data?.masterList);
        }
        if (data === "TypeOfLoan") {
          setTypeOfLoan(res?.data?.masterList);
        }
        if (data === "ExistingLoanProcessedBankList") {
          setExistingLoanProcessedBankList(res?.data?.masterList);
        }
        if (data === "MIS_StatusList") {
          setMIS_StatusList(res?.data?.masterList);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchDropDownData("CurrentLoanProcessedBank");
    fetchDropDownData("TypeOfLoan");
    fetchDropDownData("ExistingLoanProcessedBankList");
    fetchDropDownData("MIS_StatusList");
  }, []);

  useEffect(() => {
    fetchdata(currentPage);
  }, [currentPage, limit, isFilterClear]);

  useEffect(() => {
    console.log('MIS_List', MIS_List)
  }, [MIS_List])

  useEffect(() => {
    if (TotalEmployeeList > 1) {
      fetchUsers();
    }
  }, [TotalEmployeeList]);

  useEffect(() => {
    fetchUsers();
  }, [branch]);

  const DeleteId = async (val) => {
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      deleteData(MISROUTES.DELETEMIS(val), accessToken)
        .then((res) => {
          setOpen(null);
          fetchdata(currentPage);
        })
        .catch((error) => {
          setOpen(null);
          console.error("Error:", error);
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const EditID = (val) => {
    const EditUserData = MIS_List.find((item) => item._id === val);
    console.log('EditUserData', EditUserData)
    setSelectedUserData(EditUserData);
    setadduserOpen(true);
    setOpen(null);
  };

  const handleadduserClose = () => {
    setadduserOpen(false);
    setOpen(null);
    setSelectedUserId(null);
    fetchdata(currentPage);
  };

  const handleExport = async () => {
    const queryParams = {
      page: 1,
      limit: totalItems,
      branch:
        isAuthenticated === 'Branch-Admin' ? Branch : branch,
      startdate: startDate,
      enddate: endDate,
      telecallername: TelecallerName,
      typeofloan: TypeOfLoanState,
      currentloanprocessedbank: CLPB,
      existingloanprocessedbank: ELPB,
      status: MIS_Status,
    };
    const res = await getData(
      MISROUTES.GETALLMIS,
      queryParams,
      accessToken
    );
    const MIS_DATA = res?.data?.[0]?.paginatedResults ? res?.data?.[0]?.paginatedResults : [];

    // Map data to desired format
    const formattedData = MIS_DATA?.map((item, index) => {

      const MIS_Status_History = item?.MIS_Status_History?.map(status => {
        return `Status: ${status?.name}\nBank: ${status?.bank}\nRemarks: ${status?.remarks}\nDate: ${status?.date}\nHandled By: ${status?.handledBy}`
      }).join('\n\n');

      const CurrentStatusHandledBy =
        item?.MIS_Status_History?.length > 0
          ? item?.MIS_Status_History[item?.MIS_Status_History?.length - 1]?.handledBy
          : 'N/A'

      return {
        "S.No": index + 1,
        "Customer Name": item?.customerName,
        "Tele-Caller Name": item?.teleCallerName,
        "Vehicle Model": item?.vehicleModel,
        "Branch": item?.branch,
        "Current Loan Processed Bank": item?.currentLoanProcessedBank,
        "Existing Loan Processed Bank": item?.existingLoanProcessedBank,
        "Type Of Loan": item?.typeOfLoan,
        "Mobile Number": item?.mobileNumber,
        "Place": item?.place,
        "Amount": item?.amount,
        "File Processed Date": item?.fileProcessedDate,
        "Current MIS Status": item?.status,
        "Current MIS Status Handled By": CurrentStatusHandledBy,
        "Remarks": item?.remarks,
        "MIS Status History": MIS_Status_History,
      };
    });

    // Create a new workbook and add the formatted data to a worksheet
    const ExcelWorksheet = XLSX.utils.json_to_sheet(formattedData);
    const ExcelWorkbook = XLSX.utils.book_new();
    ExcelWorksheet["!cols"] = [
      { width: 5 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
    ];
    const ColumnIdentifiers = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'];
    ColumnIdentifiers.forEach((ColumnIdentifier) => {
      ExcelWorksheet[`${ColumnIdentifier}1`].s = HeaderStylesForExportExcel;
    });
    // ExcelWorksheet['A1'].s = HeaderStylesForExportExcel;
    XLSX.utils.book_append_sheet(ExcelWorkbook, ExcelWorksheet, "MIS");

    // Export the workbook as an Excel file
    XLSX.writeFile(
      ExcelWorkbook,
      `MIS-${moment()?.format("YYYY-MM-DD HH:mm:ss")}.xlsx`
    );
  };

  const [openModal, setOpenModal] = useState(false);
  const [whichModal, setWhichModal] = useState('');
  const [historyData, setHistoryData] = useState([]);

  const handleOpenModal = (historyData, modalName) => {
    setOpenModal(true);
    setWhichModal(modalName);
    setHistoryData(historyData);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setWhichModal('');
  };

  return (
    <>
      <Helmet>
        <title>MIS Report</title>
      </Helmet>
      <ToastContainer limit={1} />

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>{whichModal} History</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={TableHeadingCellStyles}>Name</TableCell>
                {whichModal === 'MIS STATUS' && (
                  <>
                    <TableCell style={TableHeadingCellStyles}>Bank</TableCell>
                    <TableCell style={TableHeadingCellStyles}>Remarks</TableCell>
                  </>
                )}
                <TableCell style={TableHeadingCellStyles}>Date</TableCell>
                <TableCell style={TableHeadingCellStyles}>Handled By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {historyData?.map((history, index) => (
                <TableRow key={index}>
                  <TableCell style={TableBodyCellStyles}>
                    <Badge
                      sx={{
                        backgroundColor:
                          history?.name === 'DISPOSED' ? '#16cc1c' :
                            history?.name === 'REJECT' ? '#e42424' :
                              history?.name === 'APPROVED - CUSTOMER DENIED' ? '#e42424' : '#4c73d0',
                        color: 'white',
                        padding: '3px 3px',
                        borderRadius: '15px',
                        width: '165px',
                        textAlign: 'center',
                        display: 'inline-block',
                      }}
                    >
                      {history?.name === 'APPROVED - CUSTOMER DENIED' ? 'APPROVED - CD' : history?.name}
                    </Badge>
                  </TableCell>
                  {whichModal === 'MIS STATUS' && (
                    <>
                      <TableCell style={TableBodyCellStyles}>{history?.bank}</TableCell>
                      <TableCell style={TableBodyCellStyles}>{history?.remarks}</TableCell>
                    </>
                  )}
                  <TableCell style={TableBodyCellStyles}>
                    {new Date(history?.date).toLocaleString()}
                  </TableCell>
                  <TableCell style={TableBodyCellStyles}>{history?.handledBy}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            {whichModal === 'MIS STATUS' && (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={12} style={{ textAlign: 'left' }}>
                    <span style={{ color: '#e42424', marginRight: '8px', fontWeight: 'bold' }}> CD: CUSTOMER DENIED </span>
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>

      <Modal open={adduseropen} onClose={handleadduserClose}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: "column",
            margin: '120px',
            gap: '10px',
            border: '1px solid #E0E0E0',   // Light gray border for a soft look
            borderRadius: '8px',            // More rounded corners for a modern feel
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
            backgroundColor: "#f9f9f9",
            padding: '16px',                // Sufficient padding for content spacing
            transition: 'all 0.3s ease',    // Smooth transition for hover effect
            '&:hover': {
              boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.15)', // More pronounced shadow on hover
              borderColor: '#bdbdbd',      // Darker border color on hover
            },
          }}
        >
          <MISComponent
            Action={selectedUserData}
            handleadduserClose={handleadduserClose}
          />
        </Box>
      </Modal>
      <h5 className="title-content-common">MIS List</h5>
      <Card className="p-4 main-card-box">
        <div className="d-flex">
          <div className="flex-wrap justify-content-between my-4">
            <div>
              <TextField
                className="my-1"
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                style={{ marginRight: 10 }}
                size="small"
              />
              <TextField
                className="my-1"
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                style={{ marginRight: 10 }}
                size="small"
              />
              <FormControl
                size="small" style={{ width: '180px' }}
                // className="py-1"
                disabled={isAuthenticated === 'Super-Admin' ? false : true}>
                <InputLabel id="branch-select-label">Branch</InputLabel>
                <Select
                  labelId="branch-select-label"
                  id="branch-select"
                  className="my-1"
                  value={branch}
                  label="Branch"
                  onChange={handleBranchChange}
                  style={{ marginRight: 8 }}
                >
                  {Branches?.map((branch) => (
                    <MenuItem key={branch} value={branch}>
                      {branch}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                size="small" style={{ width: '180px' }}>
                <InputLabel id="telecaller-select-label">Tele Callers</InputLabel>
                <Select
                  labelId="telecaller-select-label"
                  id="telecaller-select"
                  className="my-1"
                  value={TelecallerName}
                  label="Tele-Caller List"
                  onChange={handleTeleCallerNameChange}
                  style={{ marginRight: 8 }}
                >
                  {EmployeeList?.map((employee) => (
                    <MenuItem key={employee?._id} value={`${employee?.firstName} ${employee?.lastName}`}>
                      {`${employee?.firstName} ${employee?.lastName}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                size="small" style={{ width: '180px' }}>
                {/*Current Loan Processed Bank*/}
                <InputLabel id="telecaller-select-label">CLPB</InputLabel>
                <Select
                  labelId="CurrentLoanProcessedBank-select-label"
                  id="CurrentLoanProcessedBank-select"
                  className="my-1"

                  value={CLPB}
                  label="CurrentLoanProcessedBank List"
                  onChange={handleCLPBChange}
                  style={{ marginRight: 8 }}
                >
                  {CurrentLoanProcessedBank?.map((bank) => (
                    <MenuItem key={bank} value={bank}>
                      {bank}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                size="small" style={{ width: '180px' }}>
                {/*Existing Loan Processed Bank*/}
                <InputLabel id="ExistingLoanProcessedBankList-select-label"> ELPB </InputLabel>
                <Select
                  labelId="ExistingLoanProcessedBankList-select-label"
                  id="ExistingLoanProcessedBankList-select"
                  className="my-1"

                  value={ELPB}
                  label="ExistingLoanProcessedBankList List"
                  onChange={handleELPBChange}
                  style={{ marginRight: 8 }}
                >
                  {ExistingLoanProcessedBankList?.map((bank) => (
                    <MenuItem key={bank} value={bank}>
                      {bank}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                size="small" style={{ width: '180px' }}>
                <InputLabel id="telecaller-select-label"> Status </InputLabel>
                <Select
                  labelId="MIS_StatusList-select-label"
                  id="MIS_StatusList-select"
                  className="my-1"

                  value={MIS_Status}
                  label="MIS_StatusList List"
                  onChange={handleMIS_StatusChange}
                  style={{ marginRight: 8 }}
                >
                  {MIS_StatusList?.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                size="small" style={{ width: '180px' }}>
                {/* Type Of Loans */}
                <InputLabel id="telecaller-select-label">TOL</InputLabel>
                <Select
                  labelId="TypeOfLoan-select-label"
                  id="TypeOfLoan-select"
                  className="my-1"

                  value={TypeOfLoanState}
                  label="TypeOfLoan List"
                  onChange={handleTypeOfLoanChange}
                  style={{ marginRight: 8 }}
                >
                  {TypeOfLoan?.map((loan) => (
                    <MenuItem key={loan} value={loan}>
                      {loan}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                className="my-1"
                startIcon={<Iconify icon="tabler:database-search" />}
                onClick={handleSearch}
                style={{ marginRight: 8, height: '40px', width: '170px' }}
                disabled={startDate || endDate || branch || TelecallerName || CLPB || ELPB || MIS_Status || TypeOfLoanState ? false : true}
              >
                Search
              </Button>
              {(startDate || endDate || branch || TelecallerName || CLPB || ELPB || MIS_Status || TypeOfLoanState) && (
                <Button
                  variant="outlined"
                  className="me-2"
                  startIcon={<Iconify icon="mdi:clear-outline" />}
                  onClick={removeFilter}
                  style={{ marginRight: 8, height: '40px', width: '170px' }}
                >
                  Clear Filter
                </Button>
              )}
            </div>
          </div>
          <div className="justify-content-end my-4 mx-1">
            <Button
              variant="contained"
              onClick={handleExport}
              className="my-1"
              style={{ marginRight: 8, height: '40px', width: "240px" }}
              disabled={DisableDownloadButton}>
              <Iconify icon={"ic:baseline-download"} className="me-4" />
              Download MIS in Excel
            </Button>
          </div>
        </div>
        <Typography
          variant={'h7'}
          component={'div'}>
          <Iconify icon={"eos-icons:content-lifecycle-management"} className="mx-2 my-2" />
          MIS Count: {totalItems}
        </Typography>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={TableHeadingCellStyles}>Customer</TableCell>
                  <TableCell style={TableHeadingCellStyles}>Tele Caller</TableCell>
                  <TableCell style={TableHeadingCellStyles}>TOL</TableCell>
                  <TableCell style={TableHeadingCellStyles}>Model</TableCell>
                  <TableCell style={TableHeadingCellStyles}>CLPB</TableCell>
                  <TableCell style={TableHeadingCellStyles}>ELPB</TableCell>
                  <TableCell style={TableHeadingCellStyles}>Status</TableCell>
                  <TableCell style={TableHeadingCellStyles}>Branch</TableCell>
                  <TableCell style={TableHeadingCellStyles}>FPD</TableCell>
                  <TableCell style={TableHeadingCellStyles}>Amount</TableCell>
                  <TableCell style={TableHeadingCellStyles}>Status History</TableCell>
                  <TableCell style={TableHeadingCellStyles}>CLPBH</TableCell>
                  <TableCell style={TableHeadingCellStyles}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {MIS_List?.map((row) => {
                  const {
                    _id,
                    customerName,
                    teleCallerName,
                    typeOfLoan,
                    vehicleModel,
                    currentLoanProcessedBank,
                    existingLoanProcessedBank,
                    fileProcessedDate,
                    status,
                    MIS_Status_History,
                    currentLoanProcessedBank_History,
                    branch,
                    amount,
                  } = row;
                  return (
                    <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                      <TableCell component="th" scope="row" padding="none"
                        style={TableBodyCellStyles}
                      >

                        {customerName}

                      </TableCell>
                      <TableCell component="th" scope="row" padding="none"
                        style={TableBodyCellStyles} >

                        {teleCallerName}

                      </TableCell>
                      <TableCell component="th" scope="row" padding="none"
                        style={TableBodyCellStyles}>


                        {typeOfLoan}

                      </TableCell>
                      <TableCell component="th" scope="row" padding="none"
                        style={TableBodyCellStyles}>


                        {vehicleModel}

                      </TableCell>
                      <TableCell
                        style={TableBodyCellStyles}>


                        {currentLoanProcessedBank}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none"
                        style={TableBodyCellStyles}>

                        {existingLoanProcessedBank}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none"
                        style={{ ...TableBodyCellStyles, padding: "7px" }}>
                        <Badge
                          sx={{
                            backgroundColor:
                              status === 'DISPOSED' ? '#16cc1c' :
                                status === 'REJECT' ? '#e42424' : '#4c73d0',
                            color: 'white',
                            padding: '3px 3px',
                            borderRadius: '15px',
                            width: '165px',
                            textAlign: 'center',
                            display: 'inline-block',
                          }}
                        >
                          {status === 'APPROVED - CUSTOMER DENIED' ? 'APPROVED - CD' : status}
                        </Badge>
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none"
                        style={TableBodyCellStyles}>

                        {branch}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none"
                        style={TableBodyCellStyles}>


                        {fileProcessedDate?.split('T')[0]}

                      </TableCell>
                      <TableCell style={TableBodyCellStyles}>


                        {formatToRupees(Number(amount))}

                      </TableCell>
                      <TableCell style={TableBodyCellStyles}>
                        {MIS_Status_History && MIS_Status_History.length > 0 && (
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={() => handleOpenModal(MIS_Status_History, 'MIS STATUS')}
                          >
                            <Iconify icon={"iconoir:eye-solid"} />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell style={TableBodyCellStyles}>
                        {currentLoanProcessedBank_History && currentLoanProcessedBank_History.length > 0 && (
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={() => handleOpenModal(currentLoanProcessedBank_History, 'Current Loan Processed Bank')}
                          >
                            <Iconify icon={"iconoir:eye-solid"} />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell style={TableBodyCellStyles}>
                        <IconButton
                          size="large"
                          color="inherit"
                          onClick={(event) => handleOpenMenu(event, _id)}
                        >
                          <Iconify icon={"eva:more-vertical-fill"} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {MIS_List?.length === 0 && (
                  <TableRow style={TableBodyCellStyles}>
                    <TableCell colSpan={13} align="center">
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: 'left' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      <span style={{ color: '#e42424', margin: '4px', fontWeight: 'bold', flexBasis: '40%' }}>
                        CD: CUSTOMER DENIED
                      </span>
                      <span style={{ color: '#008080', margin: '4px', fontWeight: 'bold', flexBasis: '40%' }}>
                        CLPB: Current Loan Processed Bank
                      </span>
                      <span style={{ color: '#FF7F50', margin: '4px', fontWeight: 'bold', flexBasis: '40%' }}>
                        ELPB: Existing Loan Processed Bank
                      </span>
                      <span style={{ color: '#d4ac0d', margin: '4px', fontWeight: 'bold', flexBasis: '40%' }}>
                        TOL: Type Of Loan
                      </span>
                      <span style={{ color: '#6A5ACD', margin: '4px', fontWeight: 'bold', flexBasis: '40%' }}>
                        FPD: File Processed Date
                      </span>
                      <span style={{ color: '#DC143C', margin: '4px', fontWeight: 'bold', flexBasis: '40%' }}>
                        CLPBH: Current Loan Processed Bank History
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Scrollbar>
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              "& .MuiMenuItem-root": {
                px: 1,
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              EditID(selectedUserId);
            }}
          >
            <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
            Edit
          </MenuItem>

          <MenuItem
            sx={{ color: "error.main" }}
            onClick={() => {
              DeleteId(selectedUserId);
            }}
          >
            <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        </Popover>
        <div className="d-flex flex-wrap justify-content-end m-3">
          <FormControl
            variant="outlined"
            size="small"
            style={{ width: "100px" }}
          >
            <TextField
              id="limit-input"
              type="number"
              label="Limit"
              size="small"
              value={limit}
              onChange={(e) => handleLimitChange(e.target.value)}
              inputProps={{ min: 1 }}
            />
          </FormControl>
          <Button onClick={handlePrevPage} disabled={currentPage === 1}>
            <Iconify icon={"grommet-icons:previous"} />
          </Button>
          <Button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <Iconify icon={"grommet-icons:next"} />
          </Button>
        </div>
      </Card>
    </>
  );
}

export default MISReport;
