import React, { useState, useEffect } from "react";
import Scrollbar from "../../../components/scrollbar/Scrollbar";
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import * as XLSX from "sheetjs-style";
import moment from "moment";

import Iconify from "../../../components/iconify/Iconify";
import { formatToRupees } from "../../../utils/formatRupees";
import { TableHeadingCellStyles, TableBodyCellStyles } from "../../../css/tableCSS";
import { HeaderStylesForExportExcel } from "../../../css/ExportExcelCSS";

function Telecaller({ TelecallerData, DisableDownloadButton }) {
  const downloadExcel = () => {
    const groupedData = TelecallerData.reduce((acc, item) => {
      const branch = item?.branch[0];
      if (!acc[branch]) {
        acc[branch] = {
          branch,
          noOfFilesTaken: 0,
          noOfFilesDisposed: 0,
          volume: 0,
          details: [],
        };
      }
      acc[branch].noOfFilesTaken += item?.noOfFilesTaken;
      acc[branch].noOfFilesDisposed += item?.noOfFilesDisposed;
      acc[branch].volume += item?.volume;
      acc[branch].details.push(item);

      return acc;
    }, {});

    // Prepare data for Excel
    let worksheetData = [
      [
        "S.No",
        "TeleCaller Name",
        "Branch",
        "No Of Files Taken",
        "No Of Files Disposed",
        "Volume",
      ],
    ];
    let serialNumber = 1;

    const footerArrayId = [];
    Object.values(groupedData).forEach((branchData) => {
      branchData.details.forEach((item) => {
        worksheetData.push([
          serialNumber++,
          item?.teleCallerName,
          item?.branch[0],
          item?.noOfFilesTaken,
          item?.noOfFilesDisposed,
          item?.volume?.toLocaleString(),
        ]);
      });

      worksheetData.push([
        "",
        "",
        `Total for ${branchData.branch}`,
        branchData.noOfFilesTaken,
        branchData.noOfFilesDisposed,
        branchData.volume.toLocaleString(),
      ]);
      footerArrayId.push(worksheetData?.length)
      worksheetData.push([]);
    });

    // Create worksheet and workbook
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    worksheet["!cols"] = [
      { width: 5 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
    ];
    const ColumnIdentifiers = ['A', 'B', 'C', 'D', 'E', 'F'];
    ColumnIdentifiers.forEach((ColumnIdentifier) => {
      worksheet[`${ColumnIdentifier}1`].s = HeaderStylesForExportExcel;

      if( ColumnIdentifier !== 'A' ||  ColumnIdentifier !== 'B' ) {
        footerArrayId.forEach( item => {
          worksheet[`${ColumnIdentifier}${item}`].s = HeaderStylesForExportExcel;
          })
      }
    });
    XLSX.utils.book_append_sheet(
      workbook, 
      worksheet, 
      "MIS Telecaller Summary"
    );
console.log('footerArrayId', footerArrayId)
    // Define the file name
    XLSX.writeFile(
      workbook, 
      `MIS-TeleCaller-Summary-${moment().format("YYYY-MM-DD HH:mm:ss")}.xlsx`,
    );
  };

  console.log(TelecallerData, "TelecallerData");
  return (
    <>
      <Scrollbar>
        <div className="d-flex flex-wrap justify-content-end mb-3">
          <Button
            variant="contained"
            onClick={downloadExcel}
            disabled={DisableDownloadButton}
          >
            <Iconify icon={"ic:baseline-download"} className="me-2" />
            Download Telecaller Summary
          </Button>
        </div>
        <Box sx={{ display: "flex", flexDirection: "column", height: 300 }}>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={TableHeadingCellStyles}>S.No</TableCell>
                  <TableCell style={TableHeadingCellStyles}>Branch</TableCell>
                  <TableCell style={TableHeadingCellStyles}>Telecaller Name</TableCell>
                  <TableCell style={TableHeadingCellStyles}>No Of File Taken </TableCell>
                  <TableCell style={TableHeadingCellStyles}>No Of Files Disposed</TableCell>
                  <TableCell style={TableHeadingCellStyles}>Volume</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {TelecallerData?.map((row, index) => {
                  const {
                    teleCallerName,
                    noOfFilesTaken,
                    noOfFilesDisposed,
                    volume,
                    branch
                  } = row;
                  return (
                    <TableRow hover key={1} tabIndex={-1} role="checkbox">
                      <TableCell component="th" scope="row" padding="none"
                        style={TableBodyCellStyles}>
                        <Typography
                          variant="subtitle2"
                          noWrap
                          style={{ marginLeft: "10px" }}
                        >
                          {index + 1}
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={TableBodyCellStyles}>{branch[0]}</TableCell>
                      <TableCell
                        style={TableBodyCellStyles}>{teleCallerName}</TableCell>
                      <TableCell
                        style={TableBodyCellStyles}>{noOfFilesTaken}</TableCell>
                      <TableCell
                        style={TableBodyCellStyles}>{noOfFilesDisposed}</TableCell>
                      <TableCell
                        style={TableBodyCellStyles}>{formatToRupees(Number(volume))}</TableCell>
                    </TableRow>
                  );
                })}
                {TelecallerData?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} align="center" style={TableBodyCellStyles}>
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Scrollbar>
    </>
  );
}

export default Telecaller;
