import React from "react";
import Scrollbar from "../../../components/scrollbar/Scrollbar";
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import * as XLSX from "sheetjs-style";
import moment from "moment";

import Iconify from "../../../components/iconify/Iconify";
import { formatToRupees } from "../../../utils/formatRupees";
import { TableHeadingCellStyles, TableBodyCellStyles } from "../../../css/tableCSS";
import { HeaderStylesForExportExcel } from "../../../css/ExportExcelCSS";

function BankReport({ BankData, DisableDownloadButton }) {
  const createExcel = () => {
    // Convert data to the desired format for Excel
    const formattedData = BankData.map((item) => ({
      'Bank name': item?.currentLoanProcessedBank,
      'No of files taken': item?.noOfFilesTaken,
      'No of files disposed': item?.noOfFilesDisposed,
      'Volume': item?.volume?.toLocaleString(),
    }));

    // Add totals
    const noOfFilesTaken = formattedData.reduce(
      (acc, curr) => acc + curr['No of files taken'],
      0
    );
    const noOfFilesDisposed = formattedData.reduce(
      (acc, curr) => acc + curr['No of files disposed'],
      0
    );
    const totalVolume = formattedData.reduce(
      (acc, curr) => acc + parseInt(curr.Volume.replace(/,/g, "")),
      0
    );

    formattedData.push({
      'Bank name': 'Total',
      'No of files taken': noOfFilesTaken.toLocaleString(),
      'No of files disposed': noOfFilesDisposed.toLocaleString(),
      'Volume': totalVolume.toLocaleString(),
    });

    // Create worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    worksheet["!cols"] = [
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
    ];
    const ColumnIdentifiers = ['A', 'B', 'C', 'D'];
    ColumnIdentifiers.forEach((ColumnIdentifier) => {
      worksheet[`${ColumnIdentifier}1`].s = HeaderStylesForExportExcel;
      worksheet[`${ColumnIdentifier}${formattedData?.length + 1}`].s = HeaderStylesForExportExcel;
    });
    XLSX.utils.book_append_sheet(
      workbook, 
      worksheet, 
      'MIS Bank Summary'
    );

    // Generate Excel file and trigger download
    XLSX.writeFile(
      workbook, 
      `MIS-Bank-Summary-${moment().format("YYYY-MM-DD HH:mm:ss")}.xlsx`
    );
  };
  
  return (
    <>
      <Scrollbar>
        <div className="d-flex flex-wrap justify-content-end mb-3">
          <Button variant="contained" onClick={createExcel} disabled={DisableDownloadButton}>
            <Iconify icon={"ic:baseline-download"} className="me-2" />
            Download Bank Summary
          </Button>
        </div>
        <Box sx={{ display: "flex", flexDirection: "column", height: 300 }}>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={TableHeadingCellStyles}>S.No</TableCell>
                  <TableCell style={TableHeadingCellStyles}>Current Loan Processed Bank</TableCell>
                  <TableCell style={TableHeadingCellStyles}>Branch</TableCell>
                  <TableCell style={TableHeadingCellStyles}>No Of File Taken </TableCell>
                  <TableCell style={TableHeadingCellStyles}>No Of Files Disposed</TableCell>
                  <TableCell style={TableHeadingCellStyles}>Volume</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {BankData?.map((row, index) => {
                  const {
                    currentLoanProcessedBank,
                    noOfFilesTaken,
                    noOfFilesDisposed,
                    volume,
                    branch,
                  } = row;
                  return (
                    <TableRow hover key={1} tabIndex={-1} role="checkbox">
                      <TableCell component="th" scope="row" padding="none"
                        style={TableBodyCellStyles}>
                        <Typography
                          variant="subtitle2"
                          noWrap
                          style={{ marginLeft: "10px" }}
                        >
                          {index + 1}
                        </Typography>
                      </TableCell>
                      <TableCell style={TableBodyCellStyles}>{currentLoanProcessedBank}</TableCell>
                      <TableCell style={TableBodyCellStyles}>{branch[0]}</TableCell>
                      <TableCell style={TableBodyCellStyles}>{noOfFilesTaken}</TableCell>
                      <TableCell style={TableBodyCellStyles}>{noOfFilesDisposed}</TableCell>
                      <TableCell style={TableBodyCellStyles}>{formatToRupees(Number(volume))}</TableCell>
                    </TableRow>
                  );
                })}
                {BankData?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} align="center" style={TableBodyCellStyles}>
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Scrollbar>
    </>
  );
}

export default BankReport;
