import React from "react";
import Scrollbar from "../../../components/scrollbar/Scrollbar";
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import * as XLSX from "sheetjs-style";
import moment from "moment";

import Iconify from "../../../components/iconify/Iconify";
import { formatToRupees } from "../../../utils/formatRupees";
import { TableHeadingCellStyles, TableBodyCellStyles } from "../../../css/tableCSS";
import { HeaderStylesForExportExcel } from "../../../css/ExportExcelCSS";

function OverAllReport({ overAllData, DisableDownloadButton }) {
  const downloadExcel = () => {
    const summary = overAllData[0];

    const worksheetData = [
      [
        "TOTAL NO OF TELECALLERS",
        "NO OF FILE TAKEN",
        "TOTAL VOLUME",
        "NO OF FILES DISPOSED",
      ],
      [
        summary?.totalNoOfTeleCallers,
        summary?.noOfFilesTaken,
        summary?.volume?.toLocaleString(),
        summary?.noOfFilesDisposed,
      ],
    ];

    // Create worksheet and workbook
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    worksheet["!cols"] = [
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
    ];
    const ColumnIdentifiers = ['A', 'B', 'C', 'D'];
    ColumnIdentifiers.forEach((ColumnIdentifier) => {
      worksheet[`${ColumnIdentifier}1`].s = HeaderStylesForExportExcel;
    });
    XLSX.utils.book_append_sheet(
      workbook, 
      worksheet,
      'MIS Overall Summary'
    );

    // Define the file name
    XLSX.writeFile(
      workbook, 
      `MIS-Overall-Summary-${moment().format("YYYY-MM-DD HH:mm:ss")}.xlsx`
    );
  };

  return (
    <>
      <Scrollbar>
        <div className="d-flex flex-wrap justify-content-end mb-3">
          <Button variant="contained" onClick={downloadExcel} disabled={DisableDownloadButton}>
            <Iconify icon={"ic:baseline-download"} className="me-2" />
            Download Total Summary
          </Button>
        </div>
        <Box sx={{ display: "flex", flexDirection: "column", height: 300 }}>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={TableHeadingCellStyles}>S.No</TableCell>
                  <TableCell style={TableHeadingCellStyles}>No Of File Taken </TableCell>
                  <TableCell style={TableHeadingCellStyles}>No Of Files Disposed</TableCell>
                  <TableCell style={TableHeadingCellStyles}>Volume</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {overAllData?.map((row, index) => {
                  const { noOfFilesTaken, noOfFilesDisposed, volume } = row;
                  return (
                    <TableRow hover key={1} tabIndex={-1} role="checkbox">
                      <TableCell component="th" scope="row" padding="none"
                      style={TableBodyCellStyles}>
                          <Typography
                            variant="subtitle2"
                            noWrap
                            style={{ marginLeft: "10px" }}
                          >
                            {index + 1}
                          </Typography>
                      </TableCell>
                      <TableCell style={TableBodyCellStyles}>{noOfFilesTaken}</TableCell>
                      <TableCell style={TableBodyCellStyles}>{noOfFilesDisposed}</TableCell>
                      <TableCell style={TableBodyCellStyles}>{formatToRupees(Number(volume))}</TableCell>
                    </TableRow>
                  );
                })}
                {overAllData?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={4} align="center" style={TableBodyCellStyles}>
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Scrollbar>
    </>
  );
}

export default OverAllReport;
