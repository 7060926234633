import { createSlice } from "@reduxjs/toolkit";

const ReportDateSlice = createSlice({
  name: "ReportDate",
  initialState: {
    SelectedDate: "",
    StartDate: "",
    EndDate: "",
  },
  reducers: {
    setSelectedDate: (state, action) => {
      state.SelectedDate = action.payload;
    },
    setStartDate: (state, action) => {
      state.StartDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.EndDate = action.payload;
    },
  },
});

export const { setSelectedDate, setStartDate, setEndDate } =
ReportDateSlice.actions;
export default ReportDateSlice.reducer;
