const HeaderStylesForExportExcel = {
    font: {
        bold: true,
        color: "000000",
        sz: '11'
    },
    fill: {
        type: 'pattern',
        patternType: 'solid',
        fgColor: { rgb: "e8f0f8" },
    },
    border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" }
    }
}

export {
    HeaderStylesForExportExcel
}