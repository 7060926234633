import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LogoImg from "../../../../src/srimotors.png";
// @mui
import { Stack, IconButton, InputAdornment, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/iconify";
import Branches from "../../../Master/branches";
import { toast, ToastContainer } from "react-toastify";
import { postData } from "../../../webService/webService";
import USERSROUTES from "../../../API_Routes/User/User_Routers";

// ----------------------------------------------------------------------

export default function LoginForm({ onLogin }) {
  const [mail, setMail] = useState("");
  const [pwd, setPwd] = useState("");
  const [role, setRole] = useState("");
  const [branch, setBranch] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => {
    const LoginParams = {
      email: mail.trim(),
      password: pwd.trim(),
      role: role,
      branch: branch,
    };
    if (mail === "" && pwd === "" && role === "None") {
      toast.error("All The Filed's Are Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      postData(USERSROUTES.LOGIN, LoginParams, null)
        .then((res) => {
          switch (res.status) {
            case 200:
              sessionStorage.setItem("Token", res?.data?.sessionToken);
              sessionStorage.setItem("UserName", `${res?.data?.firstName} ${res?.data?.lastName}`);
              sessionStorage.setItem("UserId", res?.data?._id);
              sessionStorage.setItem("Branch", res?.data?.branch);
              onLogin(role);
              switch (role) {
                case "Admin":
                  navigate("/admin/dashboard", { replace: true });
                  break;
                default:
                  console.log("No Routes");
                  break;
              }
              break;
            case 500:
              toast.error("Error while logging in: Server error", {
                position: toast.POSITION.TOP_RIGHT,
              });
              break;
            case 400:
              toast.error("Incorrect Password", {
                position: toast.POSITION.TOP_RIGHT,
              });
              break;
            case 404:
              toast.error("User Not Found", {
                position: toast.POSITION.TOP_RIGHT,
              });
              break;
            default:
              toast.error("Unexpected Error occurred", {
                position: toast.POSITION.TOP_RIGHT,
              });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleBranchChange = (event) => {
    setBranch(event.target.value);
  };

  return (
    <>
      <ToastContainer limit={1}/>
      <Stack spacing={3}>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <img style={{ height: "40px" }} src={LogoImg} alt="logo" />
          <p
            className="mb-0 fw-bold mt-3"
            style={{ color: "#A81611", fontSize: "12px" }}
          >
            Affordable Loans, Luxurious Life
          </p>
        </div>
        <TextField
          name="email"
          label="Email address"
          value={mail}
          size="small"
          onChange={(e) => setMail(e.target.value)}
          autoComplete="off"
          required
        />
        <TextField
          size="small"
          name="password"
          label="Password"
          value={pwd}
          onChange={(e) => setPwd(e.target.value)}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="role-select-label">Role</InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select"
            value={role}
            label="Role"
            onChange={handleRoleChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"Super-Admin"}>Super Admin</MenuItem>
            <MenuItem value={"Branch-Admin"}>Branch Admin</MenuItem>
            <MenuItem value={"RC-Admin"}>RC Admin</MenuItem>
          </Select>
        </FormControl>

        {(role === "Branch-Admin" || role === "RC-Admin") && (
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="branch-select-label">Branch</InputLabel>
            <Select
              labelId="branch-select-label"
              id="branch-select"
              value={branch}
              label="Branch"
              onChange={handleBranchChange}
            >
              {Branches.map((branch) => (
                <MenuItem key={branch} value={branch}>
                  {branch}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        sx={{ my: 2 }}
        onClick={handleClick}
      >
        Login
      </LoadingButton>
    </>
  );
}
