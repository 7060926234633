import {
  Button,
  Card,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import Branches from "../../Master/branches";
import { getData, postFileUpload } from "../../webService/webService";
import BRANCHADMINROUTES from "../../API_Routes/BranchAdmin/branch_admin_Router";
import { toast, ToastContainer } from "react-toastify";
import { showFileConfirmationDialog } from "../../utils/fileuploadconfirmbox";
import USERSROUTES from "../../API_Routes/User/User_Routers";

function DailyUpdates() {
  const fileInputRef = useRef(null);
  const accessToken = sessionStorage.getItem("Token");
  const Branch = sessionStorage.getItem("Branch");
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const UserId = sessionStorage.getItem("UserId");
  const [branch, setBranch] = useState(Branch !== "undefined" ? Branch : "");

  const handleBranchChange = (event) => {
    setBranch(event.target.value);
  };

  const handleJSONChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!teleCallerName) {
      toast.error("Please select a telecaller.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const fileName = file.name;
    const isConfirmed = await showFileConfirmationDialog(fileName);

    if (isConfirmed) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("teleCallerName", teleCallerName);

      try {
        if (isAuthenticated === "Branch-Admin") {
          await postFileUpload(
            BRANCHADMINROUTES.UPLOAD_REPORT_WITHOUT_BRANCH(UserId),
            formData,
            accessToken
          );
        } else {
          await postFileUpload(
            BRANCHADMINROUTES.UPLOAD_REPORT_WITH_BRANCH(UserId, branch),
            formData,
            accessToken
          );
        }
        setBranch(Branch !== "undefined" ? Branch : "");
        setteleCallerName("");
        fileInputRef.current.value = "";
      } catch (error) {
        toast.error("An error occurred. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    } else {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  // Get Employee list
  const [teleCallerName, setteleCallerName] = useState("");
  const handleEmployeeChange = (event) => {
    setteleCallerName(event.target.value);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
      },
    },
    MenuListProps: {
      style: {
        maxHeight: 200,
      },
    },
  };

  const [EmployeeList, setEmployeeList] = useState();
  const [TotalEmployeeList, setTotalEmployeeList] = useState(1);

  const fetchdata = () => {
    const params = {
      page: 1,
      limit: TotalEmployeeList,
      role: "Employee",
      branch: branch,
    };
    getData(USERSROUTES.GETALLUSER, params, accessToken)
      .then((res) => {
        if (res.status === 404) {
          setEmployeeList();
        } else {
          setEmployeeList(res.data[0].paginatedResults);
          setTotalEmployeeList(res.data[0].totalPages);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (TotalEmployeeList > 1) {
      fetchdata();
    }
  }, [TotalEmployeeList]);

  useEffect(() => {
    fetchdata();
  }, [branch]);

  // Get Employee list

  return (
    <>
      <ToastContainer limit={1}/>
      <Helmet>
        <title>Daily-Updates</title>
      </Helmet>
      <h5 className="title-content-common">Daily Reports</h5>

      <Card className="p-4 main-card-box">
        <form>
          <div className="d-flex flex-wrap align-items-end justify-content-between my-3">
            <div className="d-flex col-6 align-items-end">
              {isAuthenticated === "Super-Admin" && (
                <div className="col-6 mx-2">
                  <Box>
                    <FormControl size="small" className="w-100 m-0">
                      <InputLabel id="branch-select-label">Branch</InputLabel>
                      <Select
                        labelId="branch-select-label"
                        id="branch-select"
                        value={branch}
                        label="Branch"
                        onChange={handleBranchChange}
                      >
                        {Branches.map((branch) => (
                          <MenuItem key={branch} value={branch}>
                            {branch}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              )}
              <FormControl
                fullWidth
                margin="normal"
                className="m-0"
                size="small"
              >
                <InputLabel style={{ background: "white" }}>
                  Tele Caller Name
                </InputLabel>
                <Select
                  label="Tele"
                  name="teleCallerName"
                  value={teleCallerName}
                  onChange={handleEmployeeChange}
                  MenuProps={MenuProps}
                >
                  {EmployeeList?.map((employee, index) => (
                    <MenuItem
                      key={index}
                      value={`${employee.firstName} ${employee.lastName}`}
                    >
                      {employee.firstName} {employee.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div>
            <input
              type="file"
              accept=".csv"
              onChange={handleJSONChange}
              ref={fileInputRef}
            />
          </div>
        </form>
      </Card>
    </>
  );
}

export default DailyUpdates;
