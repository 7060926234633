import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Card
} from "@mui/material";
import { Helmet } from "react-helmet-async";
// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container } from "@mui/material";

// sections
import {
  PieChart,
  PieChartForBankDisposed,
  PieChartForBankDisposedAmount,
  // BurndownChart
} from "../sections/@dashboard/app";
import Iconify from "../components/iconify/Iconify";
import CountMIS_Card from "../sections/@dashboard/app/CountMIS_Card";
import { getData } from "../webService/webService";
import BRANCHADMINROUTES from "../API_Routes/BranchAdmin/branch_admin_Router";
import MISROUTES from "../API_Routes/MIS/MIS_Routers";
import Dashboard_Router from "../API_Routes/Dashboard/Dashboard_Router";

import AnnaNagarTowerIMG from '../images/branch/AnnaNagarTower.png';
import PondyMandabamIMG from '../images/branch/PondyMandabam.png';
import CuddaloreTempleIMG from '../images/branch/CuddaloreTemple.png';
import CovaiClockTowerIMG from '../images/branch/CovaiClockTower.png';
import ChengalpattuTempleIMG from '../images/branch/ChengalpattuTemple.png';
import MountRoadIMG from '../images/branch/MountRoad.png';
import NelloreFortIMG from '../images/branch/NelloreFort.png';

export default function DashboardAppPage() {

  const theme = useTheme();
  const accessToken = sessionStorage.getItem("Token");

  const [MissedCallCount, setMissedCallCount] = useState(0);
  const [IncomingCallCount, setIncomingCallCount] = useState(0);
  const [OutgoingCallCount, setOutgoingCallCount] = useState(0);
  const [CallsStartDate, setCallsStartDate] = useState("");
  const [CallsEndDate, setCallsEndDate] = useState("");

  const [ApprovedCount, setApprovedCount] = useState(0);
  const [DisposedCount, setDisposedCount] = useState(0);
  const [CPACount, setCPACount] = useState(0);
  const [RejectCount, setRejectCount] = useState(0);
  const [LoginCount, setLoginCount] = useState(0);
  const [ApprovedCusDenyCount, setApprovedCusDenyCount] = useState(0);
  const [StatusStartDate, setStatusStartDate] = useState("");
  const [StatusEndDate, setStatusEndDate] = useState("");

  const [MIS_CountByBranch, setMIS_CountByBranch] = useState(0);
  const [FileProcessedStartDate, setFileProcessedStartDate] = useState("");
  const [FileProcessedEndDate, setFileProcessedEndDate] = useState("");

  const [MIS_CountByBank, setMIS_CountByBank] = useState([]);
  const [FileProcessedStartDateForBank, setFileProcessedStartDateForBank] = useState("");
  const [FileProcessedEndDateForBank, setFileProcessedEndDateForBank] = useState("");

  const [MIS_CountByBankVolume, setMIS_CountByBankVolume] = useState([]);
  const [FileProcessedStartDateForBankVolume, setFileProcessedStartDateForBankVolume] = useState("");
  const [FileProcessedEndDateForBankVolume, setFileProcessedEndDateForBankVolume] = useState("");

  const fetchReportsCountByType = async (Type) => {
    try {
      const reqBody = {
        page: 1,
        limit: 5,
        type: Type,
        starttime: CallsStartDate,
        endtime: CallsEndDate
      };
      const res = await getData(BRANCHADMINROUTES.SEEALLREPORT, reqBody, accessToken, false);

      switch (Type) {
        case 'MISSED':
          setMissedCallCount(res?.data[0]?.totalCount?.totalItems);
          break;
        case 'INCOMING':
          setIncomingCallCount(res?.data[0]?.totalCount?.totalItems);
          break;
        case 'OUTGOING':
          setOutgoingCallCount(res?.data[0]?.totalCount?.totalItems);
          break;
      }
    }
    catch (error) {
      console.error(error);
      switch (Type) {
        case 'MISSED':
          setMissedCallCount(0);
          break;
        case 'INCOMING':
          setIncomingCallCount(0);
          break;
        case 'OUTGOING':
          setOutgoingCallCount(0);
          break;
      }
    }
  };

  const fetch_MIS_CountByStatus = async (Status) => {
    try {
      const queryParams = {
        page: 1,
        limit: 5,
        status: Status,
        startdate: StatusStartDate,
        enddate: StatusEndDate
      };
      const res = await getData(MISROUTES.GETALLMIS, queryParams, accessToken, false);

      switch (Status) {
        case 'APPROVED':
          setApprovedCount(res?.data[0]?.totalCount?.totalItems);
          break;
        case 'APPROVED - CUSTOMER DENIED':
          setApprovedCusDenyCount(res?.data[0]?.totalCount?.totalItems);
          break;
        case 'DISPOSED':
          setDisposedCount(res?.data[0]?.totalCount?.totalItems);
          break;
        case 'CPA':
          setCPACount(res?.data[0]?.totalCount?.totalItems);
          break;
        case 'REJECT':
          setRejectCount(res?.data[0]?.totalCount?.totalItems);
          break;
        case 'LOGIN':
          setLoginCount(res?.data[0]?.totalCount?.totalItems);
          break;
      }
    }
    catch (error) {
      console.error(error);
      switch (Status) {
        case 'APPROVED':
          setApprovedCount(0);
          break;
        case 'APPROVED - CUSTOMER DENIED':
          setApprovedCusDenyCount(0);
          break;
        case 'DISPOSED':
          setDisposedCount(0);
          break;
        case 'CPA':
          setCPACount(0);
          break;
        case 'REJECT':
          setRejectCount(0);
          break;
        case 'LOGIN':
          setLoginCount(0);
          break;
      }
    }
  };

  const fetch_MIS_CountByBranch = async () => {
    try {
      const res = await getData(
        Dashboard_Router.FETCH_MIS_COUNT_BY_BRANCH,
        {
          startfileprocesseddate: FileProcessedStartDate,
          endfileprocesseddate: FileProcessedEndDate
        },
        accessToken,
        false
      );
      if (res?.status === 404) {
        setMIS_CountByBranch({});
        return
      }
      setMIS_CountByBranch(res?.data);
    }
    catch (error) {
      console.error(error);
    }
  };

  const fetch_MIS_CountByBank = async () => {
    try {
      const res = await getData(
        Dashboard_Router.FETCH_MIS_COUNT_BY_BANK,
        {
          startfileprocesseddate: FileProcessedStartDateForBank,
          endfileprocesseddate: FileProcessedEndDateForBank
        },
        accessToken,
        false
      );
      if (res?.status === 404) {
        setMIS_CountByBank([]);
        return
      }
      setMIS_CountByBank(res?.data);
    }
    catch (error) {
      console.error(error);
    }
  };

  const fetch_MIS_CountByBankVolume = async () => {
    try {
      const res = await getData(
        Dashboard_Router.FETCH_MIS_COUNT_BY_BANK,
        {
          startfileprocesseddate: FileProcessedStartDateForBankVolume,
          endfileprocesseddate: FileProcessedEndDateForBankVolume
        },
        accessToken,
        false
      );
      if (res?.status === 404) {
        setMIS_CountByBankVolume([]);
        return
      }
      setMIS_CountByBankVolume(res?.data);
    }
    catch (error) {
      console.error(error);
    }
  };

  const clearFileProcessedDateFilters = () => {
    setFileProcessedStartDate('');
    setFileProcessedEndDate('');
  }

  const clearCallReporteFilters = () => {
    setCallsStartDate('');
    setCallsEndDate('');
  }

  const clearMIS_StatusFilters = () => {
    setStatusStartDate('');
    setStatusEndDate('');
  }

  const clearMIS_ByBankFilters = () => {
    setFileProcessedStartDateForBank('');
    setFileProcessedEndDateForBank('');
  }

  const clearMIS_ByBankVolumeFilters = () => {
    setFileProcessedStartDateForBankVolume('');
    setFileProcessedEndDateForBankVolume('');
  }

  useEffect(() => {

    fetchReportsCountByType('MISSED');
    fetchReportsCountByType('INCOMING');
    fetchReportsCountByType('OUTGOING');

    fetch_MIS_CountByStatus('DISPOSED')
    fetch_MIS_CountByStatus('APPROVED')
    fetch_MIS_CountByStatus('APPROVED - CUSTOMER DENIED')
    fetch_MIS_CountByStatus('CPA')
    fetch_MIS_CountByStatus('LOGIN')
    fetch_MIS_CountByStatus('REJECT')

    fetch_MIS_CountByBranch()
    fetch_MIS_CountByBank()
    fetch_MIS_CountByBankVolume()

  }, [])

  useEffect(() => {
    fetch_MIS_CountByBranch()
  }, [FileProcessedStartDate, FileProcessedEndDate])

  useEffect(() => {
    fetch_MIS_CountByBank()
  }, [FileProcessedStartDateForBank, FileProcessedEndDateForBank])

  useEffect(() => {
    fetch_MIS_CountByBankVolume()
  }, [FileProcessedStartDateForBankVolume, FileProcessedEndDateForBankVolume])

  useEffect(() => {
    fetchReportsCountByType('MISSED');
    fetchReportsCountByType('INCOMING');
    fetchReportsCountByType('OUTGOING');
  }, [CallsStartDate, CallsEndDate])

  useEffect(() => {
    fetch_MIS_CountByStatus('DISPOSED')
    fetch_MIS_CountByStatus('APPROVED')
    fetch_MIS_CountByStatus('APPROVED - CUSTOMER DENIED')
    fetch_MIS_CountByStatus('CPA')
    fetch_MIS_CountByStatus('LOGIN')
    fetch_MIS_CountByStatus('REJECT')
  }, [StatusStartDate, StatusEndDate])

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>
      <h5 className="title-content-common">Dashboard</h5>
      <Container maxWidth="xl">
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} >
              <Typography gutterBottom variant="h5" component="span" p={1}>
                MIS
              </Typography>
              <div className="d-flex flex-wrap">
                {MIS_CountByBranch && (
                  <>
                    {MIS_CountByBranch['ANNA NAGAR']?.count ? <div className="col-3 p-1">
                      <CountMIS_Card colors={"#4c73d0"} branch={'ANNA NAGAR'} count={MIS_CountByBranch['ANNA NAGAR']} icon={"tdesign:tower"} _imageSrc={AnnaNagarTowerIMG} />
                    </div> :
                      <div className="col-3 p-1">
                        <CountMIS_Card colors={"#e42424"} branch={"ANNA NAGAR"} count={{ count: 0, noOfFilesDisposed: 0, volume: 0 }} icon={"tdesign:tower"} _imageSrc={AnnaNagarTowerIMG} />
                      </div>}

                    {MIS_CountByBranch['PONDY']?.count ? <div className="col-3 p-1">
                      <CountMIS_Card colors={"#4c73d0"} branch={'PONDY'} count={MIS_CountByBranch['PONDY']} icon={"ri:bar-chart-2-line"} _imageSrc={PondyMandabamIMG} />
                    </div> :
                      <div className="col-3 p-1">
                        <CountMIS_Card colors={"#e42424"} branch={"PONDY"} count={{ count: 0, noOfFilesDisposed: 0, volume: 0 }} icon={"ri:bar-chart-2-line"} _imageSrc={PondyMandabamIMG} />
                      </div>}

                    {MIS_CountByBranch['CUDDALORE']?.count ? <div className="col-3 p-1">
                      <CountMIS_Card colors={"#4c73d0"} branch={'CUDDALORE'} count={MIS_CountByBranch['CUDDALORE']} _imageSrc={CuddaloreTempleIMG} />
                    </div> :
                      <div className="col-3 p-1">
                        <CountMIS_Card colors={"#e42424"} branch={"CUDDALORE"} count={{ count: 0, noOfFilesDisposed: 0, volume: 0 }} _imageSrc={CuddaloreTempleIMG} />
                      </div>}

                    {MIS_CountByBranch['COIMBATORE']?.count ? <div className="col-3 p-1">
                      <CountMIS_Card colors={"#4c73d0"} branch={'COIMBATORE'} count={MIS_CountByBranch['COIMBATORE']} _imageSrc={CovaiClockTowerIMG} />
                    </div> :
                      <div className="col-3 p-1">
                        <CountMIS_Card colors={"#e42424"} branch={"COIMBATORE"} count={{ count: 0, noOfFilesDisposed: 0, volume: 0 }} _imageSrc={CovaiClockTowerIMG} />
                      </div>}

                    {MIS_CountByBranch['CHENGALPET']?.count ? <div className="col-3 p-1">
                      <CountMIS_Card colors={"#4c73d0"} branch={'CHENGALPET'} count={MIS_CountByBranch['CHENGALPET']} _imageSrc={ChengalpattuTempleIMG} />
                    </div> :
                      <div className="col-3 p-1">
                        <CountMIS_Card colors={"#e42424"} branch={"CHENGALPET"} count={{ count: 0, noOfFilesDisposed: 0, volume: 0 }} _imageSrc={ChengalpattuTempleIMG} />
                      </div>}

                    {MIS_CountByBranch['MOUNT ROAD']?.count ? <div className="col-3 p-1">
                      <CountMIS_Card colors={"#4c73d0"} branch={'MOUNT ROAD'} count={MIS_CountByBranch['MOUNT ROAD']} _imageSrc={MountRoadIMG} />
                    </div> :
                      <div className="col-3 p-1">
                        <CountMIS_Card colors={"#e42424"} branch={"MOUNT ROAD"} count={{ count: 0, noOfFilesDisposed: 0, volume: 0 }} _imageSrc={MountRoadIMG} />
                      </div>}

                    {MIS_CountByBranch['NELLORE']?.count ? <div className="col-3 p-1">
                      <CountMIS_Card colors={"#4c73d0"} branch={'NELLORE'} count={MIS_CountByBranch['NELLORE']} _imageSrc={NelloreFortIMG} />
                    </div> :
                      <div className="col-3 p-1">
                        <CountMIS_Card colors={"#e42424"} branch={"NELLORE"} count={{ count: 0, noOfFilesDisposed: 0, volume: 0 }} _imageSrc={NelloreFortIMG} />
                      </div>}

                    <div className="col-3 px-2 py-2">
                      <TextField
                        label="Start Date"
                        type="date"
                        value={FileProcessedStartDate}
                        onChange={(e) => setFileProcessedStartDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        style={{ margin: 6 }}
                        size="small"
                      />
                      <TextField
                        label="End Date"
                        type="date"
                        value={FileProcessedEndDate}
                        onChange={(e) => setFileProcessedEndDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        style={{ margin: 6 }}
                        size="small"
                      />
                      {(FileProcessedEndDate && FileProcessedStartDate) && (
                        <Button
                          variant="outlined"
                          className="ms-2"
                          startIcon={<Iconify icon="mdi:clear-outline" />}
                          onClick={clearFileProcessedDateFilters}
                          style={{ height: "40px", width: "150px", margin: 6 }}
                        >
                          Clear Filter
                        </Button>
                      )}
                    </div>
                  </>
                )
                }

              </div>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>

              <Card style={{ height: '580px', }} className="d-flex flex-column justify-content-between">

                <Typography gutterBottom variant="h5" component="div" className="text-center mt-3">
                  CALL TYPES
                </Typography>

                {
                  MissedCallCount === 0 && IncomingCallCount === 0 && OutgoingCallCount === 0 ?
                    (
                      <p className="text-center">No Data</p>
                    ) :
                    (
                      <PieChart
                        chartData={[
                          { label: 'Incoming Calls', value: MissedCallCount },
                          { label: 'Outgoing Calls', value: IncomingCallCount },
                          { label: 'Missed Calls', value: OutgoingCallCount },
                        ]}
                        chartColors={[
                          theme.palette.primary.main,
                          theme.palette.info.main,
                          theme.palette.warning.main,
                          theme.palette.error.main,
                        ]}
                      />
                    )}

                <span className="d-flex flex-wrap my-4"
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <TextField
                    label="Start Date"
                    type="date"
                    value={CallsStartDate}
                    onChange={(e) => setCallsStartDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginRight: 16 }}
                    size="small"
                  />
                  <TextField
                    label="End Date"
                    type="date"
                    value={CallsEndDate}
                    onChange={(e) => setCallsEndDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginRight: 16 }}
                    size="small"
                  />
                  {(CallsStartDate && CallsEndDate) && (
                    <Button
                      variant="outlined"
                      className="ms-2"
                      startIcon={<Iconify icon="mdi:clear-outline" />}
                      onClick={clearCallReporteFilters}
                      style={{ height: "40px", width: "150px" }}
                    >
                      Clear Filter
                    </Button>
                  )}
                </span>
              </Card>

            </Grid>


            <Grid item xs={12} md={6} lg={6} >
              <Card style={{ height: '580px', }} className="d-flex flex-column justify-content-between">

                <Typography gutterBottom variant="h5" component="div" className="text-center mt-3">
                  STATUS TYPES
                </Typography>
                {ApprovedCount === 0 &&
                  DisposedCount === 0 &&
                  CPACount === 0 &&
                  RejectCount === 0 &&
                  LoginCount === 0 &&
                  ApprovedCusDenyCount === 0 ?
                  (
                    <p className="text-center">No Data</p>
                  )
                  : (
                    <PieChart
                      chartData={[
                        { label: 'Approve', value: ApprovedCount },
                        { label: 'Disposed', value: DisposedCount },
                        { label: 'CPA', value: CPACount },
                        { label: 'Reject', value: RejectCount },
                        { label: 'Login', value: LoginCount },
                        { label: 'APPROVED - CUSTOMER DENIED', value: ApprovedCusDenyCount },
                      ]}
                      chartColors={[
                        theme.palette.primary.main,
                        theme.palette.info.main,
                        theme.palette.warning.main,
                        theme.palette.error.main,
                      ]}
                    />
                  )}
                <span className="d-flex flex-wrap my-4"
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <TextField
                    label="Start Date"
                    type="date"
                    value={StatusStartDate}
                    onChange={(e) => setStatusStartDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginRight: 16 }}
                    size="small"
                  />
                  <TextField
                    label="End Date"
                    type="date"
                    value={StatusEndDate}
                    onChange={(e) => setStatusEndDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginRight: 16 }}
                    size="small"
                  />
                  {(StatusStartDate && StatusEndDate) && (
                    <Button
                      variant="outlined"
                      className="ms-2"
                      startIcon={<Iconify icon="mdi:clear-outline" />}
                      onClick={clearMIS_StatusFilters}
                      style={{ height: "40px", width: "150px" }}
                    >
                      Clear Filter
                    </Button>
                  )}
                </span>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} lg={6} >
              <Card style={{ height: '580px', }} className="d-flex flex-column justify-content-between">

                <Typography gutterBottom variant="h5" component="div" className="text-center mt-3">
                  DISPOSED FILES
                </Typography>

                {MIS_CountByBank?.length > 0 ? (
                  <PieChartForBankDisposed
                    chartData={MIS_CountByBank}
                    chartColors={[
                      theme.palette.primary.main,
                      theme.palette.info.main,
                      theme.palette.warning.main,
                      theme.palette.error.main,
                    ]}
                  />

                ) : (
                  <p className="text-center">No Data</p>
                )}
                <span className="d-flex flex-wrap my-4"
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <TextField
                    label="Start Date"
                    type="date"
                    value={FileProcessedStartDateForBank}
                    onChange={(e) => setFileProcessedStartDateForBank(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginRight: 16 }}
                    size="small"
                  />
                  <TextField
                    label="End Date"
                    type="date"
                    value={FileProcessedEndDateForBank}
                    onChange={(e) => setFileProcessedEndDateForBank(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginRight: 16 }}
                    size="small"
                  />
                  {(FileProcessedStartDateForBank && FileProcessedEndDateForBank) && (
                    <Button
                      variant="outlined"
                      className="ms-2"
                      startIcon={<Iconify icon="mdi:clear-outline" />}
                      onClick={clearMIS_ByBankFilters}
                      style={{ height: "40px", width: "150px" }}
                    >
                      Clear Filter
                    </Button>
                  )}
                </span>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} lg={6} >
              <Card style={{ height: '580px', }} className="d-flex flex-column justify-content-between">

                <Typography gutterBottom variant="h5" component="div" className="text-center mt-3">
                  DISPOSED AMOUNT
                </Typography>
                {MIS_CountByBankVolume?.length > 0 ? (
                  <PieChartForBankDisposedAmount
                    chartData={MIS_CountByBankVolume}
                    chartColors={[
                      theme.palette.primary.main,
                      theme.palette.info.main,
                      theme.palette.warning.main,
                      theme.palette.error.main,
                    ]}
                  />

                ) : (
                  <p className="text-center">No Data</p>
                )}
                <span className="d-flex flex-wrap my-4"
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <TextField
                    label="Start Date"
                    type="date"
                    value={FileProcessedStartDateForBankVolume}
                    onChange={(e) => setFileProcessedStartDateForBankVolume(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginRight: 16 }}
                    size="small"
                  />
                  <TextField
                    label="End Date"
                    type="date"
                    value={FileProcessedEndDateForBankVolume}
                    onChange={(e) => setFileProcessedEndDateForBankVolume(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginRight: 16 }}
                    size="small"
                  />
                  {(FileProcessedStartDateForBankVolume && FileProcessedEndDateForBankVolume) && (
                    <Button
                      variant="outlined"
                      className="ms-2"
                      startIcon={<Iconify icon="mdi:clear-outline" />}
                      onClick={clearMIS_ByBankVolumeFilters}
                      style={{ height: "40px", width: "150px" }}
                    >
                      Clear Filter
                    </Button>
                  )}
                </span>
              </Card>
            </Grid>
          </Grid>
        </div>
        <br></br>
        {/* <div>
          <Card
            style={{ height: '500px' }}
            className="d-flex flex-column justify-content-between">
            <Typography gutterBottom variant="h5" component="div" className="text-center mt-3">
              Burn Down Chart
            </Typography>
            <BurndownChart series={[
              {
                name: "Ideal Burndown",
                data: [100, 91, 83, 75, 66, 58, 50, 41, 33, 25, 16, 8, 0]
              },
              {
                name: "Actual Burndown",
                data: [100, 95, 80, 70, 60, 55, 50, 45, 35, 30, 20, 15, 5]
              }
            ]} />
          </Card>
        </div> */}
      </Container>
    </>
  );
}
