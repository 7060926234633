const Branches = [
  "ANNA NAGAR",
  "PONDY",
  "CUDDALORE",
  "COIMBATORE",
  "CHENGALPET",
  "NELLORE",
  "MOUNT ROAD",
];
export default Branches;
