import { useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  MenuItem,
  IconButton,
  Popover,
  Button as ButtonMD
} from "@mui/material";
import { getData } from "../../../webService/webService";
import USERSROUTES from "../../../API_Routes/User/User_Routers";
import Iconify from "../../../components/iconify/Iconify";
import Button from "src/theme/overrides/Button";

export default function AccountPopover() {
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const Branch = sessionStorage.getItem("Branch");
  const accessToken = sessionStorage.getItem("Token");
  const UserName = sessionStorage.getItem("UserName");
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const handleLogout = async () => {
    try {
      const response = await getData(USERSROUTES.SIGNOUT, "", accessToken);
      if (response) {
        sessionStorage.clear();
        setOpen(null);
        setTimeout(() => {
          window.location.reload();
        }, 100);
      } else {
        console.error("Logout failed: ", response.message || "Unknown error");
      }
    } catch (error) {
      console.error("An error occurred during logout: ", error);
    }
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        className="btn-yellow-500 text-black"
        style={{
          padding: "10px",
        }}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Iconify
          icon={"icon-park-outline:people"}
          style={{ height: "20px", width: "20px" }}
        />
      </IconButton>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 2, mx: 1, px: 2.5 }}>
          
          <Typography variant="subtitle2" noWrap>
            Name: {UserName}
          </Typography>

          <Typography variant="subtitle2" noWrap>
            Role: {isAuthenticated}
          </Typography>

          {Branch && (
            <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
              {Branch}
            </Typography>
          )}
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <ButtonMD onClick={handleLogout} 
                    // variant="outlined"
                    className="m-2"
                    startIcon={<Iconify icon="el:off"/>}
                    style={{ height: "40px", width: "160px", 
                             background: '#d50000', color: 'white' }}>
          LOG OUT
        </ButtonMD>
      </Popover>
    </>
  );
}
