import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import {
  Card,
  Table,
  Button,
  Modal,
  Box,
  TextField,
  Container,
  Typography,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Stack,
  TableHead,
  Popover,
  Switch,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  postData,
  getData,
  FileUpload,
  deleteData,
  patchData,
} from "../../webService/webService";
import USERSROUTES from "../../API_Routes/User/User_Routers";
import Scrollbar from "../../components/scrollbar";
import Iconify from "../../components/iconify/Iconify";
import FILEROUTES from "../../API_Routes/File/File_Router";
import Branches from "../../Master/branches";
import { showConfirmationDialog } from "../../utils/sweetalertUtils";
import { TableHeadingCellStyles, TableBodyCellStyles, MenuProps } from "../../css/tableCSS";

export default function Agent() {
  const Branch = sessionStorage.getItem("Branch");
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const [active, setActive] = useState();
  const [adduseropen, setadduserOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    branch: Branch ? Branch : "",
    address: "",
    pincode: "",
    state: "",
    country: "",
    role: "Agent",
  });
  const [USERLIST, setUSERLIST] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const accessToken = sessionStorage.getItem("Token");

  // Table Conditions
  const [open, setOpen] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setSelectedUserId(id);
  };
  const handleCloseMenu = () => {
    setOpen(null);
    setSelectedUserId(null);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };

  const handleadduserOpen = (data) => {
    if (data === "Add") {
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
        branch: Branch !== "undefined" ? Branch : "",
        address: "",
        pincode: "",
        state: "",
        country: "",
        role: "Agent",
      });
    }
    setActive(data);
    setadduserOpen(true);
  };

  const handleadduserClose = () => {
    setadduserOpen(false);
    setOpen(null);
    setSelectedUserId(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    if (active === "Add") {
      try {
        await postData(USERSROUTES.AddUSER, formData, accessToken);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          mobileNumber: "",
          branch: Branch !== "undefined" ? Branch : "",
          address: "",
          pincode: "",
          state: "",
          country: "",
          role: "Agent",
        });
        fetchdata(currentPage);
        handleadduserClose();
      } catch (error) {
        toast.error("An error occurred. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      try {
        await patchData(
          USERSROUTES.PATCHUSER("Super-Admin", selectedUserId),
          formData,
          accessToken
        );
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          mobileNumber: "",
          branch: Branch !== "undefined" ? Branch : "",
          address: "",
          pincode: "",
          state: "",
          country: "",
          role: "Agent",
        });
        fetchdata(currentPage);
        setSelectedUserId(null);
        handleadduserClose();
      } catch (error) {
        toast.error("An error occurred. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const fetchdata = async (pageval) => {
    setFetchResult(false);
    try {
      const params = {
        page: pageval,
        limit: limit,
        role: "Agent",
        branch: Branch ? Branch : BranchFilter,
      };
      const res = await getData(USERSROUTES.GETALLUSER, params, accessToken);
      if (res?.status === 404) {
        setUSERLIST([])
        setTotalPages(1);
        setTotalItems(0);
      }
      else {
        setUSERLIST(res.data[0].paginatedResults);
        setTotalPages(res.data[0].totalPages);
        setTotalItems(res?.data?.[0]?.totalCount?.totalItems);
      }

    }
    catch (error) {
      console.error("Error:", error);
      setUSERLIST([])
      setTotalPages(1);
      setTotalItems(0);
    }
  };

  const [BranchFilter, setBranchFilter] = useState("");
  const [FetchResult, setFetchResult] = useState(false);

  const handleBranchFilterChange = (event) => {
    setBranchFilter(event.target.value);
  };

  const removeFilter = () => {
    setBranchFilter('');
    setFetchResult(true);
  }

  const handleSearch = () => {
    fetchdata(1);
  }

  useEffect(() => {
    if (FetchResult) {
      fetchdata(1);
    }
  }, [FetchResult])

  useEffect(() => {
    fetchdata(currentPage);
  }, [currentPage, limit]);

  const DeleteId = async (val) => {
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      deleteData(USERSROUTES.DELETEUSER("Super-Admin", val), accessToken)
        .then((res) => {
          setOpen(null);
          fetchdata(currentPage);
        })
        .catch((error) => {
          setOpen(null);
          console.error("Error:", error);
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  useEffect(() => {
    if (selectedUserId) {
      const EditUserData = USERLIST.find((item) => item._id === selectedUserId);
      setFormData({
        firstName: EditUserData.firstName,
        lastName: EditUserData.lastName,
        email: EditUserData.email,
        mobileNumber: EditUserData.mobileNumber,
        branch: EditUserData.branch,
        address: EditUserData.address,
        pincode: EditUserData.pincode,
        state: EditUserData.state,
        country: EditUserData.country,
        role: EditUserData.role,
      });
    }
  }, [selectedUserId]);

  return (
    <>
      <Helmet>
        <title>Agent</title>
      </Helmet>

      <ToastContainer limit={1} />

      <Modal open={adduseropen} onClose={handleadduserClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
          }}
        >
          <h5>{active} Agent</h5>
          <div className="d-flex flex-wrap">
            <div className="col-4 px-1">
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                margin="normal"
                size="small"
              />
            </div>
            <div className="col-4 px-1">
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                margin="normal"
                size="small"
              />
            </div>
            <div className="col-4 px-1">
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                margin="normal"
                autoComplete="off"
                size="small"
              />
            </div>
            <div className="col-4 px-1">
              <TextField
                fullWidth
                label="Mobile Number"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                margin="normal"
                type="number"
                size="small"
              />
            </div>
            {isAuthenticated === "Super-Admin" && (
              <div className="col-4 px-1">
                <FormControl fullWidth margin="normal" size="small">
                  <InputLabel>Branch</InputLabel>
                  <Select
                    label="Branch"
                    name="branch"
                    value={formData.branch}
                    onChange={handleChange}
                  >
                    {Branches.map((branch) => (
                      <MenuItem key={branch} value={branch}>
                        {branch}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}

            <div className="col-4 px-1">
              <TextField
                fullWidth
                label="Address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                margin="normal"
                size="small"
              />
            </div>
            <div className="col-4 px-1">
              <TextField
                fullWidth
                label="Pincode"
                name="pincode"
                value={formData.pincode}
                onChange={handleChange}
                margin="normal"
                type="number"
                size="small"
              />
            </div>
            <div className="col-4 px-1">
              <TextField
                fullWidth
                label="State"
                name="state"
                value={formData.state}
                onChange={handleChange}
                margin="normal"
                size="small"
              />
            </div>
            <div className="col-4 px-1">
              <TextField
                fullWidth
                label="Country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                margin="normal"
                size="small"
              />
            </div>
            <div className="col-4 px-1">
              <FormControl fullWidth margin="normal" size="small" disabled>
                <InputLabel>Role</InputLabel>
                <Select
                  label="Role"
                  name="role"
                  value={formData.role}
                  onChange={handleChange}
                >
                  <MenuItem value="Agent">Agent</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <Button
              variant="contained"
              color="error"
              className="mx-3 btn-danger"
              onClick={handleadduserClose}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="btn-green"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>

      <h5 className="title-content-common">Agent</h5>

      <Card className="p-4 main-card-box">
        {!Branch && (<div className="d-flex flex-wrap justify-content-between w-100 mb-5">
          <div className="d-flex flex-wrap">
            <FormControl
              size="small"
              className="mx-2"
              style={{ width: "160px" }}
            >
              <InputLabel id="branch-select-label">Branch</InputLabel>
              <Select
                labelId="branch-select-label"
                id="branch-select"
                value={BranchFilter}
                label="Branch"
                MenuProps={MenuProps}
                onChange={handleBranchFilterChange}
              >
                {Branches?.map((branch) => (
                  <MenuItem key={branch} value={branch}>
                    {branch}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              className="mx-2"
              startIcon={<Iconify icon="material-symbols:person-search-outline" />}
              onClick={handleSearch}
              style={{ marginRight: 8, height: '40px', width: '160px' }}
              disabled={BranchFilter ? false : true}
            >
              Search
            </Button>
            {(BranchFilter) && (
              <Button
                variant="outlined"
                className="me-2"
                startIcon={<Iconify icon="mdi:clear-outline" />}
                onClick={removeFilter}
                style={{ marginRight: 8, height: '40px', width: '160px' }}
              >
                Clear Filter
              </Button>
            )}
          </div>
          <Button
            variant="contained"
            startIcon={<Iconify icon="material-symbols:person-add-outline" />}
            onClick={() => handleadduserOpen("Add")}
          >
            Add Agent
          </Button>
        </div>)}
        {Branch && (<div className="d-flex flex-wrap justify-content-end w-100 mb-5">
          <Button
            variant="contained"
            startIcon={<Iconify icon="material-symbols:person-add-outline" />}
            onClick={() => handleadduserOpen("Add")}
          >
            Add Agent
          </Button>
        </div>)}
        <Typography
          variant={'h7'}
          component={'div'}>
          <Iconify icon={"icons8:spy"} className="mx-2 my-2" />
          Total Agents: {totalItems}
        </Typography>
        <Scrollbar>
          <Box sx={{ display: "flex", flexDirection: "column", height: 500 }}>
            <TableContainer sx={{ minWidth: 1000 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={TableHeadingCellStyles}>First Name</TableCell>
                    <TableCell style={TableHeadingCellStyles}>Last Name</TableCell>
                    <TableCell style={TableHeadingCellStyles}>Role</TableCell>
                    <TableCell style={TableHeadingCellStyles}>Email</TableCell>
                    <TableCell style={TableHeadingCellStyles}>Branch</TableCell>
                    <TableCell style={TableHeadingCellStyles}>Mobile Number</TableCell>
                    <TableCell style={TableHeadingCellStyles}>Address</TableCell>
                    <TableCell style={TableHeadingCellStyles}>Pincode</TableCell>
                    <TableCell style={TableHeadingCellStyles}>State</TableCell>
                    <TableCell style={TableHeadingCellStyles}>Country</TableCell>
                    <TableCell style={TableHeadingCellStyles}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {USERLIST?.map((row) => {
                    const { _id, firstName, lastName, role, email, branch, mobileNumber, address, pincode, state, country } = row;
                    return (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                        <TableCell component="th" scope="row" padding="none"
                          style={TableBodyCellStyles}>
                          <Typography
                            variant="subtitle2"
                            noWrap
                            style={{ marginLeft: "10px" }}
                          >
                            {firstName}
                          </Typography>
                        </TableCell>
                        <TableCell style={TableBodyCellStyles}>{lastName}</TableCell>
                        <TableCell style={TableBodyCellStyles}>{role}</TableCell>
                        <TableCell style={TableBodyCellStyles}>{email}</TableCell>
                        <TableCell style={TableBodyCellStyles}> {branch}</TableCell>
                        <TableCell style={TableBodyCellStyles}>{mobileNumber}</TableCell>
                        <TableCell style={TableBodyCellStyles}>{address}</TableCell>
                        <TableCell style={TableBodyCellStyles}>{pincode}</TableCell>
                        <TableCell style={TableBodyCellStyles}>{state}</TableCell>
                        <TableCell style={TableBodyCellStyles}>{country}</TableCell>
                        <TableCell style={TableBodyCellStyles}>
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(event) => handleOpenMenu(event, _id)}
                          >
                            <Iconify icon={"eva:more-vertical-fill"} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {USERLIST?.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={11} align="center" style={TableBodyCellStyles}>
                        No Data
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Scrollbar>
        <div className="d-flex flex-wrap justify-content-end m-3">
          <FormControl variant="outlined" size="small">
            <InputLabel id="limit-label">Limit</InputLabel>
            <Select
              labelId="limit-label"
              id="limit-select"
              value={limit}
              onChange={handleLimitChange}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
            </Select>
          </FormControl>
          <Button onClick={handlePrevPage} disabled={currentPage === 1}>
            <Iconify icon={"grommet-icons:previous"} />
          </Button>
          <Button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <Iconify icon={"grommet-icons:next"} />
          </Button>
        </div>
      </Card>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleadduserOpen("Edit");
          }}
        >
          <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem
          sx={{ color: "error.main" }}
          onClick={() => {
            DeleteId(selectedUserId);
          }}
        >
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
