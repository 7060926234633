const USERSROUTES = {
  LOGIN: "users/login",
  SIGNOUT: "users/signout",

  AddUSER: "users/signup",
  GETALLUSER: `users/users`,
  GETUSER: (USER) => `users/users/role/${USER}`,
  DELETEUSER: (USER, ID) => `users/user/${ID}/${USER}`,
  PATCHUSER: (USER, ID) => `users/user/${ID}/${USER}`,
};

export default USERSROUTES;
