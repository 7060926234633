const BRANCHADMINROUTES = {
  UPLOAD_REPORT_WITHOUT_BRANCH: (ID) => `report/bulk/csv/${ID}`,
  UPLOAD_REPORT_WITH_BRANCH: (ID, branch) =>
    `report/bulk/csv/${ID}?branch=${branch}`,

  SEEALLREPORT: `report/all`,
  // REPORT: (branch, time, type) =>
  //   `report/all?branch=${branch}&time=${time}&type=${type}`,
};

export default BRANCHADMINROUTES;
