const TableHeadingCellStyles = {
    color: "#4c73d0",
    border: "1px dotted #ccc",
    textAlign: 'center',
    fontSize: '18px'
}
const TableBodyCellStyles = { border: "1px dotted #ccc", textAlign: 'center' }
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 200,
        },
    },
    MenuListProps: {
        style: {
            maxHeight: 200,
        },
    },
};

export {
    TableHeadingCellStyles,
    TableBodyCellStyles,
    MenuProps
}