import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
// pages
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import DashboardAppPage from "./pages/DashboardAppPage";
import DailyUpdates from "./pages/Employee/daily-updates";
import Reports from "./pages/Employee/reports";
import Role from "./pages/Role/role";
import AdminReport from "./pages/Admin/report";
import TeamMember from "./pages/TeamMembers/teammembers";
import Agent from "./pages/Agent/agent";
import MISComponent from "./pages/MIS/mis";
import MISReport from "./pages/MIS/misReport";
import MISSummery from "./pages/MIS/missummery";
import RcDetails from "./pages/RC/rcDetails";

// ----------------------------------------------------------------------
export default function Router({ isAuthenticated, onLogin, onLogout }) {
  console.log("isAuthenticated:", isAuthenticated);

  const routes = useRoutes([
    {
      path: "/admin",
      element:
        isAuthenticated === "Super-Admin" ? (
          <DashboardLayout onLogout={onLogout} />
        ) : (
          <Navigate to="/login" />
        ),
      children: [
        { path: "dashboard", element: <DashboardAppPage /> },
        { path: "role", element: <Role /> },
        { path: "daily-updates", element: <DailyUpdates /> },
        { path: "report", element: <Reports /> },
        { path: "employees", element: <TeamMember /> },
        { path: "agent", element: <Agent /> },
        { path: "mis", element: <MISComponent /> },
        { path: "mis-report", element: <MISReport /> },
        { path: "mis-summery", element: <MISSummery /> },
        { path: "rc-details", element: <RcDetails /> },
      ],
    },
    {
      path: "/employee",
      element:
        isAuthenticated === "Branch-Admin" ? (
          <DashboardLayout onLogout={onLogout} />
        ) : (
          <Navigate to="/login" />
        ),
      children: [
        { path: "daily-updates", element: <DailyUpdates /> },
        { path: "reports", element: <Reports /> },
        { path: "employees", element: <TeamMember /> },
        { path: "agent", element: <Agent /> },
        { path: "mis", element: <MISComponent /> },
        { path: "mis-report", element: <MISReport /> },
      ],
    },
    {
      path: "/rc-admin",
      element:
        isAuthenticated === "RC-Admin" ? (
          <DashboardLayout onLogout={onLogout} />
        ) : (
          <Navigate to="/login" />
        ),
      children: [{ path: "rc-details", element: <RcDetails /> }],
    },
    {
      path: "/login",
      element:
        isAuthenticated === "Super-Admin" ? (
          <Navigate to="/admin/dashboard" />
        ) : isAuthenticated === "Branch-Admin" ? (
          <Navigate to="/employee/daily-updates" />
        ) : isAuthenticated === "RC-Admin" ? (
          <Navigate to="/rc-admin/rc-details" />
        ) : (
          <LoginPage onLogin={onLogin} />
        ),
    },
    {
      element: <SimpleLayout />,
      children: [
        {
          element: (
            <Navigate
              to={
                isAuthenticated === "Super-Admin"
                  ? "/admin/dashboard"
                  : isAuthenticated === "Branch-Admin"
                  ? "/employee/daily-updates"
                  : "/login"
              }
            />
          ),
          index: true,
        },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
