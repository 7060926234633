const MISROUTES = {
  ADDMIS: "MIS",
  GETALLMIS: `MIS/all/data`,
  DELETEMIS: (ID) => `MIS/${ID}`,
  UPDATEMIS: (ID) => `MIS/${ID}`,

  FILESBYTELECALLER: "MIS/loan/telecaller/analytics",
  FILESBYBANK: "MIS/loan/current_bank/analytics",
  FILESOFALL: "MIS/loan/all/analytics",
};

export default MISROUTES;
