import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Typography,
  FormControl,
  Button,
  Badge,
  TextField,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import * as XLSX from "sheetjs-style";
import moment from "moment";

import Scrollbar from "../../components/scrollbar/Scrollbar";
import Iconify from "../../components/iconify/Iconify";
import { getData } from "../../webService/webService";
import BRANCHADMINROUTES from "../../API_Routes/BranchAdmin/branch_admin_Router";
import formatDate from "../../utils/timeutils";
import { TableHeadingCellStyles, TableBodyCellStyles } from "../../css/tableCSS";
import { HeaderStylesForExportExcel } from "../../css/ExportExcelCSS";

// Custom scroll bar styling
const CustomTableContainer = styled(TableContainer)`
  // height: 350px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const TimeDisplay = ({ hours, minutes, seconds }) => {
  const padTime = (time) => String(time).padStart(2, "0");
  return (
    <TableCell>
      {padTime(hours)} : {padTime(minutes)} : {padTime(seconds)}
    </TableCell>
  );
};

const ReportTable = ({
  Type,
  dateField,
  startDate,
  endDate,
  clearSelectedDate,
  setClearSelectedDate,
  branch,
  duration,
  teleCallerName,
}) => {
  const accessToken = sessionStorage.getItem("Token");
  const Branch = sessionStorage.getItem("Branch");
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");

  // Table Conditions
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [TotalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(5);
  const [ReportList, setReportList] = useState([]);

  const [DisableDownloadButton, setDisableDownloadButton] = useState(false);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLimitChange = (value) => {
    setCurrentPage(1);
    setLimit(Number(value));
  };

  const fetchdata = (pageval) => {
    const params = {
      page: pageval,
      limit: limit,
      branch: isAuthenticated === "Branch-Admin" ? Branch : branch,
      starttime: startDate,
      endtime: endDate,
      type: Type,
      duration: duration,
      telecallername: teleCallerName !== "undefined" ? teleCallerName : "",
    };
    getData(BRANCHADMINROUTES.SEEALLREPORT, params, accessToken)
      .then((res) => {
        if (res?.status === 404) {
          setTotalCount(0);
          setDisableDownloadButton(true);
        }
        if (res?.data?.[0]?.paginatedResults?.length > 0) {
          setReportList(res?.data?.[0]?.paginatedResults);
          setTotalPages(res?.data?.[0]?.totalPages);
          setTotalCount(res?.data?.[0]?.totalCount?.totalItems);
          setDisableDownloadButton(false);
        }
        else {
          setReportList([]);
          setTotalPages(1);
          setTotalCount(0);
          setDisableDownloadButton(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setDisableDownloadButton(true);
      });
  };
  useEffect(() => {
    if (dateField) {
      setCurrentPage(1);
      setReportList([]);
      fetchdata(currentPage);
    }
  }, [dateField]);

  useEffect(() => {
    if (duration) {
      setCurrentPage(1);
      setReportList([]);
      fetchdata(currentPage);
    }
  }, [duration]);

  useEffect(() => {
    if (teleCallerName) {
      setCurrentPage(1);
      setReportList([]);
      fetchdata(currentPage);
    }
  }, [teleCallerName]);

  useEffect(() => {
    if (branch) {
      setCurrentPage(1);
      setReportList([]);
      fetchdata(currentPage);
    }
  }, [branch]);

  useEffect(() => {
    if (clearSelectedDate) {
      setCurrentPage(1);
      setReportList([]);
      fetchdata(currentPage);
      setClearSelectedDate(false);
    }
  }, [clearSelectedDate]);

  useEffect(() => {
    console.log("limit");
    fetchdata(currentPage);
  }, [currentPage, limit]);

  const handleExport = async () => {

    const params = {
      page: 1,
      limit: TotalCount,
      branch: isAuthenticated === "Branch-Admin" ? Branch : branch,
      starttime: startDate,
      endtime: endDate,
      type: Type,
      duration: duration,
      telecallername: teleCallerName !== "undefined" ? teleCallerName : "",
    };
    const exportRes = await getData(BRANCHADMINROUTES.SEEALLREPORT, params, accessToken);

    let ReportExportList = [];

    if (exportRes?.status === 404) {
      ReportExportList = [];
    }
    else {
      ReportExportList = exportRes?.data?.[0]?.paginatedResults;
    }

    const formattedData = ReportExportList?.map((item, index) => {
      return {
        "S.No": index + 1,
        "Customer Name": item?.name,
        "Tele-Caller Name": item?.teleCallerName,
        "Customer Phone Number": item?.number,
        "Branch": item?.branch,
        "Date": item?.date,
        "Duration": item?.duration,
        "Call Type": item?.type,
      };
    });

    // Create a new workbook and add the formatted data to a worksheet
    const ExcelWorksheet = XLSX.utils.json_to_sheet(formattedData);
    const ExcelWorkbook = XLSX.utils.book_new();

    ExcelWorksheet["!cols"] = [
      { width: 5 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 30 },
      { width: 25 },
      { width: 25 },
    ];
    const ColumnIdentifiers = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
    ColumnIdentifiers.forEach((ColumnIdentifier) => {
      ExcelWorksheet[`${ColumnIdentifier}1`].s = HeaderStylesForExportExcel;
    });

    XLSX.utils.book_append_sheet(ExcelWorkbook, ExcelWorksheet, "Report");

    // Export the workbook as an Excel file
    XLSX.writeFile(ExcelWorkbook, `Reports-${moment()?.format("YYYY-MM-DD HH:mm:ss")}.xlsx`);
  };

  return (
    <>
      <Typography
        variant={'h7'}
        component={'div'}>
        <Iconify icon={"tabler:file-report"} className="mx-2" />
        Total Reports: {TotalCount}
      </Typography>
      <div className="d-flex flex-wrap justify-content-end mb-3">
        <Button variant="contained" onClick={handleExport} className="me-2" disabled={DisableDownloadButton}>
          <Iconify icon={"ic:baseline-download"} className="me-2" />
          Download Reports in Excel
        </Button>
      </div>

      <CustomTableContainer>
        <Scrollbar>
          <Box sx={{ display: "flex", flexDirection: "column", height: 400 }}>
            <TableContainer sx={{ minWidth: 1000 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell style={TableHeadingCellStyles}>Customer Name</TableCell>
                    <TableCell style={TableHeadingCellStyles}>Phone</TableCell>
                    <TableCell style={TableHeadingCellStyles}>Branch</TableCell>
                    <TableCell style={TableHeadingCellStyles}>Tele Caller Name</TableCell>
                    <TableCell style={TableHeadingCellStyles}>Date</TableCell>
                    <TableCell style={TableHeadingCellStyles}>Duration</TableCell>
                    <TableCell style={TableHeadingCellStyles}>Type</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {ReportList && ReportList.length > 0 ? (
                    ReportList.map((row) => {
                      const {
                        _id,
                        name,
                        number,
                        date,
                        duration,
                        type,
                        branch,
                        totalCount,
                        teleCallerName,
                      } = row;
                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                          <TableCell
                            style={TableBodyCellStyles}
                            component="th"
                            scope="row"
                            padding="none"
                          >
                            <Typography
                              variant="subtitle2"
                              noWrap
                              style={{ marginLeft: "10px" }}
                            >
                              {name}
                            </Typography>
                          </TableCell>
                          <TableCell style={TableBodyCellStyles}>{number}</TableCell>
                          <TableCell style={TableBodyCellStyles}>{branch}</TableCell>
                          <TableCell style={TableBodyCellStyles}>{teleCallerName}</TableCell>
                          <TableCell style={TableBodyCellStyles}>
                            {formatDate(date)}
                          </TableCell>
                          <TableCell style={TableBodyCellStyles}>{duration}</TableCell>
                          <TableCell style={TableBodyCellStyles}>
                            <Badge
                              sx={{
                                backgroundColor: type === 'INCOMING' ? '#16cc1c' : type === 'MISSED' ? '#e42424' : '#bbd417',
                                color: 'white',
                                padding: '3px 3px',
                                borderRadius: '15px',
                                width: '125px',
                                textAlign: 'center',
                                display: 'inline-block',
                              }}
                            >
                              {type}
                            </Badge>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} style={TableBodyCellStyles}>
                        No data
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Scrollbar>
        <div className="d-flex flex-wrap justify-content-end m-3">
          <FormControl
            variant="outlined"
            size="small"
            style={{ width: "100px" }}
          >
            <TextField
              id="limit-input"
              type="number"
              label="Limit"
              size="small"
              value={limit}
              onChange={(e) => handleLimitChange(e.target.value)}
              inputProps={{ min: 1 }}
            />
          </FormControl>
          <Button onClick={handlePrevPage} disabled={currentPage === 1}>
            <Iconify icon={"grommet-icons:previous"} />
          </Button>
          <Button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <Iconify icon={"grommet-icons:next"} />
          </Button>
        </div>
      </CustomTableContainer>
    </>
  );
};

export default ReportTable;
