import API_URL from "../utils/url";
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
const getData = async (route, queryParamsArg, token, isToastNeeded = true, module = '') => {
  try {
    const URL_ROUTE = `${API_URL}${route}`;
    const headers = {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    };

    let url = URL_ROUTE;
    if (queryParamsArg) {
      const queryParams = new URLSearchParams(queryParamsArg).toString();
      url += `?${queryParams}`;
    }

    const options = {
      method: 'GET',
      headers: headers,
    };

    const response = await fetch( url, options );
    const responseData = await response.json();
    if ( response.status === 401 ) {
      await MySwal.fire({
        title: "Session Expired",
        text: 'Session Expired. Kindly, Login Again',
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
    if (response.status === 200) {
      console.log(responseData.message);
    }
    if( isToastNeeded && response.status === 200 ){
      if( module === 'RC' ){
        toast.success('RC fetched successfully', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      else{
        toast.success(responseData.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
    if( isToastNeeded ){
      if( response?.status === 404 ){
        if( module === 'RC' ){
          toast.error( 'No RC found' || "An error occurred", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        else {
          toast.error(responseData?.message || "An error occurred", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    }
    return responseData;
  } catch (error) {
    console.error(error);
    if( isToastNeeded ){
      if( module === 'RC' ){
        toast.error( 'Error fetching RC' || "An error occurred", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      else {
        toast.error(error.message || "An error occurred", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
    throw error;
  }
};

const postData = async (route, data, token) => {
  try {
    const URL_ROUTE = `${API_URL}${route}`;

    const headers = {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    };

    const response = await fetch(URL_ROUTE, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to fetch");
    }
    const responseData = await response.json();
    if (response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
    if (response.status === 200) {
      toast.success(responseData.message, {
        position: toast.POSITION.TOP_RIGHT,
        transition: Slide,
      });
    }
    return responseData;
  } catch (error) {
    toast.error(error.message || "An error occurred", {
      position: toast.POSITION.TOP_RIGHT,
      transition: Slide,
    });
    console.error(error);
    throw error;
  }
};

const postFileUpload = async (route, data, token) => {
  try {
    const URL_ROUTE = `${API_URL}${route}`;
    const headers = {
      Authorization: `${token}`,
    };

    const response = await fetch(URL_ROUTE, {
      method: "POST",
      headers: headers,
      body: data,
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to fetch");
    }
    const responseData = await response.json();
    if (response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
    if (response.status === 200) {
      toast.success(responseData.message, {
        position: toast.POSITION.TOP_RIGHT,
        transition: Slide,
      });
    }
    return responseData;
  } catch (error) {
    toast.error(error.message || "An error occurred", {
      position: toast.POSITION.TOP_RIGHT,
      transition: Slide,
    });
    console.error(error);
    throw error;
  }
};

const FileUpload = async (route, data) => {
  try {
    const URL_ROUTE = `${API_URL}${route}`;
    const response = await fetch(URL_ROUTE, {
      method: "POST",
      body: data,
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to fetch");
    }
    const responseData = await response.json();
    if (response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
    if (response.status === 200) {
      toast.success(responseData.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    return responseData;
  } catch (error) {
    console.error(error);
    toast.error(error.message || "An error occurred", {
      position: toast.POSITION.TOP_RIGHT,
    });
    throw error;
  }
};

const patchData = async (route, data, token, module = '') => {
  try {
    const URL_ROUTE = `${API_URL}${route}`;
    const headers = {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    };

    const response = await fetch(URL_ROUTE, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
    if (response.status === 200) {
      if( module === 'RC' ){
        toast.success('RC updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      else{
        toast.success(responseData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
    return responseData;
  } catch (error) {
    console.error(error);
    if( module === 'RC' ){
      toast.error('Error while updating RC' || "An error occurred", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else{
      toast.error(error.message || "An error occurred", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    throw error;
  }
};

const deleteData = async (route, token) => {
  try {
    const URL_ROUTE = `${API_URL}${route}`;
    const headers = {
      Authorization: `${token}`,
    };

    const response = await fetch(URL_ROUTE, {
      method: "DELETE",
      headers: headers,
    });
    const responseData = await response.json();
    if (response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
    if (response.status === 200) {
      toast.success(responseData.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    return responseData;
  } catch (error) {
    console.error(error);
    toast.error(error.message || "An error occurred", {
      position: toast.POSITION.TOP_RIGHT,
    });
    throw error;
  }
};

export { getData, postData, patchData, deleteData, FileUpload, postFileUpload };
