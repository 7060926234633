import React from "react";
import {
  Card,
  Typography
} from "@mui/material";

import { formatToRupees } from "../../../utils/formatRupees";

function CountMIS_Card({ colors, branch, count, _imageSrc }) {
  return (
    <>
      <Card
        variant="outlined"
        className="p-4"
        style={{ maxWidth: '330px', maxHeight: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <Typography gutterBottom variant="h5" component="div" style={{ color: colors }}>
              {branch}
            </Typography>
            <div>
              <Typography gutterBottom variant="p" component="span" style={{ color: colors }}>
                Moved: {count?.count}
              </Typography>
            </div>

            <div>
              <Typography gutterBottom variant="p" component="span" style={{ color: colors }}>
                Disposed: {count?.noOfFilesDisposed}
              </Typography>
            </div>

            <div>
              <Typography gutterBottom variant="p" component="span" style={{ color: colors }}>
                Amount: {formatToRupees(count?.volume)}
              </Typography>
            </div>


          </div>

          <div className="custom-image-div">
            <img src={_imageSrc} alt={branch} style={{ width: '100%', height: '80px' }} />
          </div>

        </div>
      </Card>
    </>
  );
}


export default CountMIS_Card;
