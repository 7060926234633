import { Box, Card, IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import Telecaller from "./Summery/telecaller";
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import * as XLSX from "sheetjs-style";
import moment from "moment";

import Branches from "../../Master/branches";
import { getData } from "../../webService/webService";
import MISROUTES from "../../API_Routes/MIS/MIS_Routers";
import BankReport from "./Summery/bank";
import OverAllReport from "./Summery/overall";
import Iconify from "../../components/iconify/Iconify";
import { HeaderStylesForExportExcel } from "../../css/ExportExcelCSS";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function MISSummery() {
  const accessToken = sessionStorage.getItem("Token");
  const [activeTab, setActiveTab] = useState(0);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [branch, setBranch] = useState("");
  const [TelecallerData, setTelecallerData] = useState([]);
  const [BankData, setBankData] = useState([]);
  const [overAllData, setoverAllData] = useState([]);
  const [fetchdata, setfetchdata] = useState(false);

  const [DisableDownloadButtonTele, setDisableDownloadButtonTele] = useState('');
  const [DisableDownloadButtonBank, setDisableDownloadButtonBank] = useState('');
  const [DisableDownloadButtonOverAll, setDisableDownloadButtonOverAll] = useState('');


  const handleBranchChange = (event) => {
    setBranch(event.target.value);
  };

  const filterData = () => {
    setfetchdata(false);
    const query = {
      startdate: startDate,
      enddate: endDate,
      branch: branch,
    };

    setDisableDownloadButtonTele(false);
    setDisableDownloadButtonBank(false);
    setDisableDownloadButtonOverAll(false);

    // Fetch data for telecaller
    getData(MISROUTES.FILESBYTELECALLER, query, accessToken)
      .then((res) => {
        setTelecallerData(res.data);
        if (res.status === 404) {
          setTelecallerData([]);
          setDisableDownloadButtonTele(true);
        }
        console.log("Telecaller Data:", res);
      })
      .catch((error) => {
        console.error("Error fetching telecaller data:", error);
        setDisableDownloadButtonTele(true);
      });

    // Fetch data for bank
    getData(MISROUTES.FILESBYBANK, query, accessToken)
      .then((res) => {
        setBankData(res.data);
        if (res.status === 404) {
          setBankData([]);
          setDisableDownloadButtonBank(true);
        }
        console.log("Bank Data:", res);
      })
      .catch((error) => {
        console.error("Error fetching bank data:", error);
        setDisableDownloadButtonBank(true);
      });

    // Fetch overall data
    getData(MISROUTES.FILESOFALL, query, accessToken)
      .then((res) => {
        setoverAllData(res.data);
        if (res.status === 404) {
          setoverAllData([]);
          setDisableDownloadButtonOverAll(true);
        }
        console.log("Overall Data:", res);
      })
      .catch((error) => {
        console.error("Error fetching overall data:", error);
        setDisableDownloadButtonOverAll(true);
      });
  };

  const handleSearch = () => {
    filterData();
  };

  const removeFilter = () => {
    setStartDate("");
    setEndDate("");
    setBranch("");
    setfetchdata(true);
  };
  useEffect(() => {
    filterData();
  }, []);

  useEffect(() => {
    if (fetchdata) {
      filterData();
    }
  }, [fetchdata]);

  const downloadAllDataToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Initialize combined data array
    let combinedData = [];

    // 1. Tele-caller based summary for each branch Section
    combinedData.push(["Tele-caller based summary for each branch"]);
    const headingOneLen = combinedData.length
    console.log('headingOneLen', headingOneLen)

    const groupedData = TelecallerData.reduce((acc, item) => {
      const branch = item.branch[0];
      if (!acc[branch]) {
        acc[branch] = {
          branch,
          noOfFilesTaken: 0,
          noOfFilesDisposed: 0,
          volume: 0,
          details: [],
        };
      }
      acc[branch].noOfFilesTaken += item.noOfFilesTaken;
      acc[branch].noOfFilesDisposed += item.noOfFilesDisposed;
      acc[branch].volume += item.volume;
      acc[branch].details.push(item);

      return acc;
    }, {});

    const SummaryHeadersLoc = []
    Object.values(groupedData).forEach((branchData) => {
      combinedData.push([
        "S.No",
        "TeleCaller Name",
        "Branch",
        "No Of Files Taken",
        "No Of Files Disposed",
        "Volume",
      ]);
      SummaryHeadersLoc.push(combinedData.length)

      branchData.details.forEach((item, index) => {
        combinedData.push([
          (index + 1),
          item.teleCallerName,
          item.branch[0],
          item.noOfFilesTaken,
          item.noOfFilesDisposed,
          item.volume.toLocaleString(),
        ]);
      });

      combinedData.push([
        "",
        "",
        'TOTAL',
        branchData.noOfFilesTaken,
        branchData.noOfFilesDisposed,
        branchData.volume.toLocaleString(),
      ]);
      SummaryHeadersLoc.push(combinedData.length)

      combinedData.push([]);
    });

    combinedData.push([]);

    // 2. Bank based summary for all branches
    combinedData.push(["Bank based summary for all branches"]);
    const headingTwoLen = combinedData.length
    console.log('headingTwoLen', headingTwoLen)
    combinedData.push(["Bank Name", "No Of Files Taken", "No Of Files Disposed", "Volume"]);

    const formattedBankData = BankData.map((item) => ({
      BankName: item.currentLoanProcessedBank,
      noOfFilesTaken: item.noOfFilesTaken,
      noOfFilesDisposed: item.noOfFilesDisposed,
      Volume: item.volume.toLocaleString(),
    }));

    formattedBankData.forEach((item) => {
      combinedData.push([item.BankName, item.noOfFilesTaken, item.noOfFilesDisposed, item.Volume]);
    });

    const totalNoOfFilesTaken = formattedBankData.reduce(
      (acc, curr) => acc + curr.noOfFilesTaken,
      0
    );
    const totalnoOfFilesDisposed = formattedBankData.reduce(
      (acc, curr) => acc + curr.noOfFilesDisposed,
      0
    );
    const totalBankVolume = formattedBankData.reduce(
      (acc, curr) => acc + parseInt(curr.Volume.replace(/,/g, "")),
      0
    );

    combinedData.push([
      "TOTAL",
      totalNoOfFilesTaken,
      totalnoOfFilesDisposed,
      totalBankVolume.toLocaleString(),
    ]);

    combinedData.push([]);

    // 3. Overall Summary Section
    combinedData.push(["Overall Summary for all branches"]);
    const headingThreeLen = combinedData.length
    console.log('headingThreeLen', headingThreeLen)
    combinedData.push([
      "Total No Of Telecellers",
      "No Of File Taken",
      "Total Volume",
      "No Of Files Disposed",
    ]);

    const summary = overAllData[0];
    combinedData.push([
      summary.totalNoOfTeleCallers,
      summary.noOfFilesTaken,
      summary.volume.toLocaleString(),
      summary.noOfFilesDisposed,
    ]);


    // Create worksheet from combined data
    const combinedWorksheet = XLSX.utils.aoa_to_sheet(combinedData);
    combinedWorksheet[`A${headingOneLen}`].s = { font: HeaderStylesForExportExcel.font };
    combinedWorksheet[`A${headingTwoLen}`].s = { font: HeaderStylesForExportExcel.font };
    combinedWorksheet[`A${headingThreeLen}`].s = { font: HeaderStylesForExportExcel.font };

    SummaryHeadersLoc.forEach((loc) => {
      console.log('combinedWorksheet[`A${loc}`].', combinedWorksheet[`A${loc}`])
      if (combinedWorksheet[`A${loc}`].v) {
        combinedWorksheet[`A${loc}`].s = HeaderStylesForExportExcel;
      }
      combinedWorksheet[`A${headingTwoLen + 1}`].s = HeaderStylesForExportExcel;
      combinedWorksheet[`A${headingThreeLen + 1}`].s = HeaderStylesForExportExcel;
      combinedWorksheet[`A${headingThreeLen - 2}`].s = HeaderStylesForExportExcel;

      if (combinedWorksheet[`B${loc}`].v) {
        combinedWorksheet[`B${loc}`].s = HeaderStylesForExportExcel;
      }
      combinedWorksheet[`B${headingTwoLen + 1}`].s = HeaderStylesForExportExcel;
      combinedWorksheet[`B${headingThreeLen + 1}`].s = HeaderStylesForExportExcel;
      combinedWorksheet[`B${headingThreeLen - 2}`].s = HeaderStylesForExportExcel;

      combinedWorksheet[`C${loc}`].s = HeaderStylesForExportExcel;
      combinedWorksheet[`C${headingTwoLen + 1}`].s = HeaderStylesForExportExcel;
      combinedWorksheet[`C${headingThreeLen + 1}`].s = HeaderStylesForExportExcel;
      combinedWorksheet[`C${headingThreeLen - 2}`].s = HeaderStylesForExportExcel;

      combinedWorksheet[`D${loc}`].s = HeaderStylesForExportExcel;
      combinedWorksheet[`D${headingTwoLen + 1}`].s = HeaderStylesForExportExcel;
      combinedWorksheet[`D${headingThreeLen + 1}`].s = HeaderStylesForExportExcel;
      combinedWorksheet[`D${headingThreeLen - 2}`].s = HeaderStylesForExportExcel;

      combinedWorksheet[`E${loc}`].s = HeaderStylesForExportExcel;
      combinedWorksheet[`F${loc}`].s = HeaderStylesForExportExcel;
    })
    combinedWorksheet["!cols"] = [
      { width: 40 },
      { width: 30 },
      { width: 30 },
      { width: 20 },
      { width: 20 },
      { width: 20 }
    ];
    XLSX.utils.book_append_sheet(
      workbook,
      combinedWorksheet,
      "MIS-Summary"
    );

    // Generate Excel file and trigger download
    XLSX.writeFile(
      workbook,
      `MIS-Summary-${moment().format("YYYY-MM-DD HH:mm:ss")}.xlsx`,
      { compression: true }
    );

  };

  return (
    <>
      <Helmet>
        <title>MISSummery</title>
      </Helmet>
      <ToastContainer limit={3} />
      <h5 className="title-content-common">MIS Summery</h5>
      <Card className="p-4 main-card-box">
        <div className="d-flex flex-wrap justify-content-between my-4">
          <div>
            <TextField
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              style={{ marginRight: 16 }}
              size="small"
            />
            <TextField
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              style={{ marginRight: 16 }}
              size="small"
            />
            <FormControl size="small" style={{ width: "160px" }}>
              <InputLabel id="branch-select-label">Branch</InputLabel>
              <Select
                labelId="branch-select-label"
                id="branch-select"
                value={branch}
                label="Branch"
                onChange={handleBranchChange}
              >
                {Branches.map((branch) => (
                  <MenuItem key={branch} value={branch}>
                    {branch}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              className="btn-green mx-3"
              startIcon={<Iconify icon="tabler:database-search" />}
              onClick={handleSearch}
              style={{ marginRight: 8, height: '40px', width: '160px' }}
              disabled={startDate || endDate || branch ? false : true}
            >
              Search
            </Button>
            {(startDate || endDate || branch) && (
              <Button
                variant="outlined"
                className="me-2"
                startIcon={<Iconify icon="mdi:clear-outline" />}
                onClick={removeFilter}
                style={{ marginRight: 8, height: '40px', width: '160px' }}
              >
                Clear Filter
              </Button>
            )}
          </div>
          <div>
            <Button 
              variant="contained" 
              onClick={downloadAllDataToExcel} 
              disabled={DisableDownloadButtonTele && DisableDownloadButtonBank && DisableDownloadButtonOverAll}>
              <Iconify icon={"ic:baseline-download"} className="me-2" />
              Download MIS Summary
            </Button>
          </div>
        </div>
        <div className="border mb-4"></div>
        <Box>
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#f8bd51",
              },
              "& .MuiTab-root": {
                textTransform: "none",
                color: "#000",
              },
              "& .Mui-selected": {
                color: "#c6150f!important",
              },
            }}
          >
            <Tab label="TeleCaller" />
            <Tab label="Bank" />
            <Tab label="Over All" />
          </Tabs>

          <TabPanel value={activeTab} index={0}>
            <Telecaller TelecallerData={TelecallerData} DisableDownloadButton={DisableDownloadButtonTele} />
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <BankReport BankData={BankData} DisableDownloadButton={DisableDownloadButtonBank} />
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            <OverAllReport overAllData={overAllData} DisableDownloadButton={DisableDownloadButtonOverAll} />
          </TabPanel>
        </Box>
      </Card>
    </>
  );
}

export default MISSummery;
