import {
  Badge,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TextField,
  Typography,
} from "@mui/material";
import * as XLSX from "sheetjs-style";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";

import Scrollbar from "../../components/scrollbar";
import Iconify from "../../components/iconify/Iconify";
import { getData, patchData } from "../../webService/webService";
import MISROUTES from "../../API_Routes/MIS/MIS_Routers";
import MASTERROUTES from "../../API_Routes/Master/Master_Routers";
import { ConfirmationDialog } from "../../utils/conformalert";
import { formatToRupees } from "../../utils/formatRupees";
import Branches from "../../Master/branches";
import { HeaderStylesForExportExcel } from "../../css/ExportExcelCSS";
import { TableHeadingCellStyles, TableBodyCellStyles } from "../../css/tableCSS";

function RcDetails() {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
      },
    },
    MenuListProps: {
      style: {
        maxHeight: 200,
      },
    },
  };
  const [RC_LIST, setRC_LIST] = useState([]);
  const accessToken = sessionStorage.getItem("Token");
  const [FetchResult, setFetchResult] = useState(false);
  const [RCStatusList, setRCStatusList] = useState();
  const [
    RC_StatusExternalHandlersList,
    setRC_StatusExternalHandlersList
  ] = useState([]);

  const [DisableDownloadButton, setDisableDownloadButton] = useState(false);

  // Table Conditions
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [limit, setLimit] = useState(5);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleLimitChange = (value) => {
    setCurrentPage(1);
    setLimit(Number(value));
  };

  const fetchdata = (pageval) => {
    setFetchResult(false);
    const params = {
      page: pageval,
      limit: limit,
      status: "DISPOSED",
      rcstatusname: filterrcstatus,
      rcstatushandledby: handleby,
      rcstatusexternallyhandledby: ExternalHandlerFilter,
      branch: branch,
    };
    getData(MISROUTES.GETALLMIS, params, accessToken, true, 'RC')
      .then((res) => {
        if (res?.status === 404) {
          setDisableDownloadButton(true);
          setRC_LIST([]);
          setTotalPages(1);
          setTotalItems(0);
        }
        else {
          if (res?.data?.[0]?.paginatedResults?.length > 0) {
            setDisableDownloadButton(false);
            setRC_LIST(res?.data?.[0]?.paginatedResults);
            setTotalPages(res?.data?.[0]?.totalPages);
            setTotalItems(res?.data?.[0]?.totalCount?.totalItems);
          }
          else {
            setDisableDownloadButton(true);
            setRC_LIST([]);
            setTotalPages(1);
            setTotalItems(0);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setDisableDownloadButton(true);
        setRC_LIST([]);
        setTotalPages(1);
        setTotalItems(0);
      });
  };

  const fetchRCStatusList = () => {
    getData(MASTERROUTES.GETLIST("RC_StatusList"), null, accessToken, false)
      .then((res) => {
        setRCStatusList(res.data.masterList);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const fetchRC_StatusExternalHandlersList = async () => {
    try {
      const RC_StatusExternalHandlersListRes = await getData(
        MASTERROUTES.GETLIST("RC_StatusExternalHandlersList"),
        null,
        accessToken,
        false
      );
      const ExternalHandlersList = RC_StatusExternalHandlersListRes?.data?.masterList?.length > 0 ?
        RC_StatusExternalHandlersListRes?.data?.masterList : [];

      const AgentNamesRes = await getData(
        MASTERROUTES.GET_AGENT_NAMES,
        null,
        accessToken,
        false
      );

      const AgentNamesList = AgentNamesRes?.data?.agentNames?.length > 0 ?
        AgentNamesRes?.data?.agentNames : [];

      setRC_StatusExternalHandlersList(
        [...ExternalHandlersList, ...AgentNamesList]
      );

    }
    catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchRCStatusList();
    fetchRC_StatusExternalHandlersList();
  }, []);

  useEffect(() => {
    fetchdata(currentPage);
  }, [currentPage, limit]);

  const handleChange = async (event, userId) => {
    const isConfirmed = await ConfirmationDialog(event?.target?.value);
    if (isConfirmed) {
      const selectedStatusName = event?.target?.value;
      const Branch = sessionStorage.getItem("Branch");
      const handledBy = Branch !== "undefined" ? `${Branch}` : "Super-Admin";

      const newStatus = {
        name: selectedStatusName,
        date: new Date(),
        handledBy,
      };

      let updatedRCStatus;
      setRC_LIST((prevList) => {
        return prevList.map((user) => {
          if (user._id === userId) {
            updatedRCStatus = user.RC_Status
              ? [...user.RC_Status, newStatus]
              : [newStatus];

            console.log(updatedRCStatus, "updatedRCStatus");

            return { ...user, RC_Status: updatedRCStatus };
          }
          return user;
        });
      });

      const params = {
        RC_Status: updatedRCStatus,
        lastUpdatedRC_Status: selectedStatusName
      };

      patchData(`${MISROUTES.UPDATEMIS(userId)}`, params, accessToken, 'RC')
        .then((res) => {
          if (res.status === 200) {
            console.log("Status updated successfully!");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const handleExternalHandlerChange = async (event, userId) => {

    const isConfirmed = await ConfirmationDialog(event?.target?.value);
    if (isConfirmed) {
      const bodyData = {
        externallyHandledBy: event?.target?.value,
      };

      patchData(`${MISROUTES.UPDATEMIS(userId)}`, bodyData, accessToken, 'RC')
        .then((res) => {
          if (res.status === 200) {
            console.log("Status updated successfully!");
            fetchdata(currentPage);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  const [openModal, setOpenModal] = useState(false);
  const [selectedRCStatus, setSelectedRCStatus] = useState([]);

  const handleOpenModal = (rcStatus) => {
    setSelectedRCStatus(rcStatus);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Filter.
  const [branch, setBranch] = useState("");
  const [filterrcstatus, setfilterrcstatus] = useState("");
  const [handleby, sethandleby] = useState("");
  const [ExternalHandlerFilter, setExternalHandlerFilter] = useState("");

  const handleBranchChange = (event) => {
    setBranch(event.target.value);
  };
  const handleRCstatusChange = (event) => {
    setfilterrcstatus(event.target.value);
  };
  const handleHandlebyChange = (event) => {
    sethandleby(event.target.value);
  };
  const handleExternalHandlerFilterChange = (event) => {
    setExternalHandlerFilter(event.target.value);
  };
  const removeFilter = () => {
    setRC_LIST([]);
    setBranch("");
    setfilterrcstatus("");
    setExternalHandlerFilter("");
    sethandleby("");
    setFetchResult(true);
  };
  const handleSearch = () => {
    setRC_LIST([]);
    fetchdata(1);
  };

  useEffect(() => {
    if (FetchResult) {
      fetchdata(1);
    }
  }, [FetchResult]);

  const handleExport = async () => {

    const queryParams = {
      page: 1,
      limit: totalItems,
      status: "DISPOSED",
      rcstatusname: filterrcstatus,
      rcstatushandledby: handleby,
      rcstatusexternallyhandledby: ExternalHandlerFilter,
      branch: branch,
    };

    const res = await getData(
      MISROUTES.GETALLMIS,
      queryParams,
      accessToken,
      true,
      'RC'
    );
    const RC_DATA = res?.data[0]?.paginatedResults;

    // Map data to desired format
    const formattedData = RC_DATA?.map((item, index) => {

      const RC_Status_History = item?.RC_Status?.map(status => {
        return `Status: ${status?.name}\nDate: ${status?.date}\nHandled By: ${status?.handledBy}`
      }).join('\n\n');

      const lastRCHandledBy =
        item?.RC_Status?.length > 0
          ? item?.RC_Status[item?.RC_Status?.length - 1]?.handledBy
          : "N/A";

      return {
        "S.No": index + 1,
        "Customer Name": item?.customerName,
        "Branch": item?.branch,
        "Current Loan Processed Bank": item?.currentLoanProcessedBank,
        "Existing Loan Processed Bank": item?.existingLoanProcessedBank,
        "Telecaller Name": item?.teleCallerName,
        "Mobile Number": item?.mobileNumber,
        "Place": item?.place,
        "Current RC Status": item?.lastUpdatedRC_Status,
        "Current RC Status Handled By": lastRCHandledBy,
        "Externally Handled By": item?.externallyHandledBy,
        "RC Status History": RC_Status_History
      };
    });

    // Create a new workbook and add the formatted data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    worksheet["!cols"] = [
      { width: 5 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
    ];
    const ColumnIdentifiers = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];
    ColumnIdentifiers.forEach((ColumnIdentifier) => {
      worksheet[`${ColumnIdentifier}1`].s = HeaderStylesForExportExcel;
    });
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      'RC'
    );

    // Export the workbook as an Excel file
    XLSX.writeFile(
      workbook,
      `RC-${moment()?.format("YYYY-MM-DD HH:mm:ss")}.xlsx`
    );
  };
  return (
    <>
      <Helmet>
        <title>RCList</title>
      </Helmet>
      <ToastContainer limit={1} />

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>RC Status Details</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={TableHeadingCellStyles}>Status</TableCell>
                <TableCell style={TableHeadingCellStyles}>Date</TableCell>
                <TableCell style={TableHeadingCellStyles}>Handled By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedRCStatus?.map((statusItem, index) => (
                <TableRow key={index}>
                  <TableCell style={TableBodyCellStyles} >
                    <Badge
                      sx={{
                        backgroundColor:
                          statusItem?.name === 'UPDATED' ? '#16cc1c' :
                            /PENDING/i.test(statusItem?.name) ? '#e42424' : '#4c73d0',
                        color: 'white',
                        padding: '3px 3px',
                        borderRadius: '15px',
                        width: '165px',
                        textAlign: 'center',
                        display: 'inline-block',
                      }}
                    >
                      {statusItem.name}
                    </Badge>
                  </TableCell>
                  <TableCell style={TableBodyCellStyles} >
                    {new Date(statusItem.date).toLocaleString()}
                  </TableCell>
                  <TableCell style={TableBodyCellStyles} >{statusItem.handledBy}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
      <h5 className="title-content-common">RC</h5>
      <Card className="p-4 main-card-box">
        <div className="d-flex flex-wrap justify-content-between my-4">
          <div className="d-flex flex-wrap">
            <FormControl
              size="small"
              className="mx-2"
              style={{ width: "120px" }}
            >
              <InputLabel id="branch-select-label">Branch</InputLabel>
              <Select
                labelId="branch-select-label"
                id="branch-select"
                value={branch}
                label="Branch"
                onChange={handleBranchChange}
              >
                {Branches.map((branch) => (
                  <MenuItem key={branch} value={branch}>
                    {branch}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              size="small"
              className="mx-2"
              style={{ width: "120px" }}
            >
              <InputLabel>RC Status</InputLabel>
              <Select
                value={filterrcstatus}
                onChange={handleRCstatusChange}
                MenuProps={MenuProps}
                label="RCStatus"
              >
                {RCStatusList?.map((list) => (
                  <MenuItem key={list} value={list}>
                    {list}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              size="small"
              className="mx-2"
              style={{ width: "120px" }}
            >
              <InputLabel>Handle By</InputLabel>
              <Select
                value={handleby}
                onChange={handleHandlebyChange}
                MenuProps={MenuProps}
                label="Handle By"
              >
                <MenuItem key={"Super-Admin"} value={"Super-Admin"}>Super-Admin</MenuItem>
                {Branches?.map((branch) => (
                  <MenuItem key={branch} value={branch}>
                    {branch}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              size="small"
              className="mx-2"
              style={{ width: "120px" }}
            >
              <InputLabel>EHB</InputLabel>
              <Select
                value={ExternalHandlerFilter}
                onChange={handleExternalHandlerFilterChange}
                MenuProps={MenuProps}
                label="Externally Handled By"
              >
                {RC_StatusExternalHandlersList?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              className="btn-green mx-2"
              startIcon={<Iconify icon="tabler:database-search" />}
              onClick={handleSearch}
              style={{ height: '40px', width: '160px' }}
              disabled={branch || filterrcstatus || handleby || ExternalHandlerFilter ? false : true}
            >
              Search
            </Button>
            {(branch || filterrcstatus || handleby || ExternalHandlerFilter) && (
              <Button
                variant="outlined"
                className="mx-2"
                startIcon={<Iconify icon="mdi:clear-outline" />}
                onClick={removeFilter}
                style={{ height: '40px', width: '160px' }}
              >
                Clear Filter
              </Button>
            )}
          </div>

          <div className="justify-content-end mx-1">
            <Button
              variant="contained"
              nClick={handleExport}
              disabled={DisableDownloadButton}
              className="mx-2"
              style={{ marginRight: 8, height: '40px', width: "240px" }} >
              <Iconify icon={"ic:baseline-download"} className="me-2" />
              Download RC Summary
            </Button>
          </div>
        </div>
        <Typography
          variant={'h7'}
          component={'div'}>
          <Iconify icon={"gg:list"} className="mx-2 my-2" />
          RC Count: {totalItems}
        </Typography>
        <Scrollbar>
          {/* <Box sx={{ display: "flex", flexDirection: "column", height: 400 }}> */}
          <TableContainer sx={{ minWidth: 800 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={TableHeadingCellStyles}>Customer</TableCell>
                  <TableCell style={TableHeadingCellStyles}>Tele Caller</TableCell>
                  <TableCell style={TableHeadingCellStyles}>Branch</TableCell>
                  <TableCell style={TableHeadingCellStyles}>TOL</TableCell>
                  <TableCell style={TableHeadingCellStyles}>Model</TableCell>
                  <TableCell style={TableHeadingCellStyles}>CLPB</TableCell>
                  <TableCell style={TableHeadingCellStyles}>ELPB</TableCell>
                  <TableCell style={TableHeadingCellStyles}>FPD</TableCell>
                  {/* <TableCell style={TableHeadingCellStyles}>Status</TableCell> */}
                  <TableCell style={TableHeadingCellStyles}>RC Status</TableCell>
                  <TableCell style={TableHeadingCellStyles}>EHB</TableCell>
                  <TableCell style={TableHeadingCellStyles}>Amount</TableCell>
                  <TableCell style={TableHeadingCellStyles}>RCSH</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {RC_LIST?.map((row) => {
                  const {
                    _id,
                    customerName,
                    teleCallerName,
                    typeOfLoan,
                    vehicleModel,
                    currentLoanProcessedBank,
                    existingLoanProcessedBank,
                    fileProcessedDate,
                    status,
                    amount,
                    RC_Status,
                    externallyHandledBy,
                    branch,
                  } = row;
                  return (
                    <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                      <TableCell component="th" scope="row" padding="none" style={TableBodyCellStyles}>


                        {customerName}

                      </TableCell>
                      <TableCell component="th" scope="row" padding="none" style={TableBodyCellStyles}>

                        {teleCallerName}

                      </TableCell>
                      <TableCell style={TableBodyCellStyles} >


                        {branch}

                      </TableCell>
                      <TableCell style={TableBodyCellStyles} >


                        {typeOfLoan}

                      </TableCell>
                      <TableCell style={TableBodyCellStyles} >


                        {vehicleModel}

                      </TableCell>
                      <TableCell style={TableBodyCellStyles} >


                        {currentLoanProcessedBank}

                      </TableCell>
                      <TableCell style={TableBodyCellStyles} >


                        {existingLoanProcessedBank}

                      </TableCell>
                      <TableCell style={TableBodyCellStyles} >


                        {fileProcessedDate?.split('T')[0]}

                      </TableCell>
                      <TableCell style={TableBodyCellStyles} >
                        <FormControl fullWidth margin="normal" size="small">
                          <Select
                            value={
                              RC_Status && RC_Status?.length > 0
                                ? RC_Status?.slice(-1)[0].name
                                : ""
                            }
                            onChange={(event) => handleChange(event, _id)}
                            MenuProps={MenuProps}
                          >
                            {RCStatusList?.map((list) => (
                              <MenuItem key={list} value={list}>
                                {list}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell style={TableBodyCellStyles} >
                        <FormControl fullWidth margin="normal" size="small">
                          <Select
                            value={externallyHandledBy}
                            onChange={(event) => handleExternalHandlerChange(event, _id)}
                            MenuProps={MenuProps}
                          >
                            {RC_StatusExternalHandlersList?.map((list) => (
                              <MenuItem key={list} value={list}>
                                {list}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell style={TableBodyCellStyles} >


                        {formatToRupees(Number(amount))}

                      </TableCell>
                      <TableCell style={TableBodyCellStyles} >
                        {RC_Status && RC_Status.length > 0 && (
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={() => handleOpenModal(RC_Status)}
                          >
                            <Iconify icon={"iconoir:eye-solid"} />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {RC_LIST?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={12} align="center" style={TableBodyCellStyles}>
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: 'left' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      <span style={{ color: '#008080', margin: '4px', fontWeight: 'bold', flexBasis: '40%' }}>
                        RCSH: RC Status History
                      </span>
                      <span style={{ color: '#008080', margin: '4px', fontWeight: 'bold', flexBasis: '40%' }}>
                        CLPB: Current Loan Processed Bank
                      </span>
                      <span style={{ color: '#FF7F50', margin: '4px', fontWeight: 'bold', flexBasis: '40%' }}>
                        ELPB: Existing Loan Processed Bank
                      </span>
                      <span style={{ color: '#d4ac0d', margin: '4px', fontWeight: 'bold', flexBasis: '40%' }}>
                        TOL: Type Of Loan
                      </span>
                      <span style={{ color: '#6A5ACD', margin: '4px', fontWeight: 'bold', flexBasis: '40%' }}>
                        FPD: File Processed Date
                      </span>
                      <span style={{ color: '#6A5ACD', margin: '4px', fontWeight: 'bold', flexBasis: '40%' }}>
                        EHB: Externally Handled By
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          {/* </Box> */}
        </Scrollbar >
        <div className="d-flex flex-wrap justify-content-end m-3">
          <FormControl
            variant="outlined"
            size="small"
            style={{ width: "100px" }}
          >
            <TextField
              id="limit-input"
              type="number"
              label="Limit"
              size="small"
              value={limit}
              onChange={(e) => handleLimitChange(e.target.value)}
              inputProps={{ min: 1 }}
            />
          </FormControl>
          <Button onClick={handlePrevPage} disabled={currentPage === 1}>
            <Iconify icon={"grommet-icons:previous"} />
          </Button>
          <Button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <Iconify icon={"grommet-icons:next"} />
          </Button>
        </div>
      </Card >
    </>
  );
}

export default RcDetails;
