export const formatToRupees = (num) => {
    try {
        // Convert the number to a string and split it into integer and decimal parts
        let [integerPart, decimalPart] = num?.toString()?.split(".");

        // Regular expression to add commas in the Indian style
        let lastThreeDigits = integerPart?.slice(-3);
        let otherDigits = integerPart?.slice(0, -3);
        if (otherDigits !== "") {
            lastThreeDigits = "," + lastThreeDigits;
        }

        let formattedNumber = otherDigits?.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;

        // Return the formatted number with the rupee symbol and decimal part (if any)
        return "₹" + formattedNumber + (decimalPart ? "." + decimalPart : "");
    }
    catch (error) {
        console.error(error);
        return num;
    }
}