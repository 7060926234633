import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";
// components
import Logo from "../components/logo";
// sections
import { LoginForm } from "../sections/auth/login";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding:"10px 40px",
  background: "#ffffff",
  boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
  borderRadius: "10px",
  border: " 1px solid rgba( 255, 255, 255, 0.18 )",
}));

const MainContent = styled("div")(() => ({
  background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://api.sricrm.digitaltriumph.co.in/uploads/template/19-07-2024-14-58-40-bg.jpg)`,
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "local",
  backgroundPosition: "center",
  backgroundSize: "cover",
  width: "100%",
  display: "flex",
  height: "100vh",
  justifyContent: "center",
  alignItems: "center",
}));

// ----------------------------------------------------------------------

export default function LoginPage({ onLogin }) {
  return (
    <>
      <Helmet>
        <title> Login </title>
      </Helmet>

      <StyledRoot>
        <MainContent>
          {/* <Logo
            sx={{
              position: 'fixed',
              top: { xs: 16, sm: 24, md: 40 },
              left: { xs: 16, sm: 24, md: 40 },
            }}
          /> */}
          <Container maxWidth="sm">
            <StyledContent>
              <LoginForm onLogin={onLogin} />
            </StyledContent>
          </Container>
        </MainContent>
      </StyledRoot>
    </>
  );
}
